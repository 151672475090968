import { Box } from '@mui/material'
import { Button, FlexText, Form, Input, Table } from 'Components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from '../Inventory.module.scss'
import ReviewConfirm from '../../Bookings/components/ReviewConfirm'
import SuccessfulModal from '../../Bookings/components/Successful'
import { apiRequest } from 'Services'
import { useQuery } from 'react-query'
import { selectOpts } from 'utils'
import { toast } from 'react-toastify'
import { useBranches } from 'hooks'
import { closeModal } from 'store/slices/modalSlice'
import { useDispatch } from 'react-redux'
import { BiPlus } from 'react-icons/bi'
import { Header } from 'Partial/Header'
import { Close } from '@mui/icons-material'

const Drop = ({ placeholder, options, onchange, idKey = 'id', labelKey = 'name', name }) => {
  return (
    <select className='' name={name} onChange={onchange}>
      <option value="">{placeholder}</option>
      {options?.map(x => <option value={x[idKey]}>{x[labelKey]}  </option>)
      }
    </select>
  )
}


const RequestInvTransfer = ({ state = 0 }) => {
  const dispatch = useDispatch()
  const { branches } = useBranches()

  const [formValues, setFormValues] = useState({})
  const [item, setItem] = useState()
  const [unit, setUnit] = useState()
  const [details, setDetails] = useState({})
  const { data: items } = useQuery(`/admin/inventory-items`)
  const [loading, setLoading] = useState(false)

  const [entries, setEntries] = useState([])

  const { data: all_source } = useQuery(`/admin/inventory-source`)
  const { data: source_from } = useQuery(`/admin/branch/${formValues?.branch_id_from}/inventory-source`, { enabled: Boolean(formValues?.branch_id_from) });
  const { data: source_to } = useQuery(`/admin/branch/${formValues?.branch_id_to}/inventory-source`, { enabled: Boolean(formValues?.branch_id_to) });

  const handleChange = (e) => {
    setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  useEffect(() => {
    if (formValues?.boh) {
      setFormValues(prev => ({ ...prev, 'moh': prev.boh * 10 }))
    }
    //get item unit  by from source type of ingedient unit
    if (formValues?.inventory_item_id && formValues?.from_inventory_source) {
      const list = items?.data
      const ingredient = list?.find(item => item?.id === parseInt(formValues?.inventory_item_id))
      setItem(ingredient)
      const source_from = all_source?.data?.find(source => source?.id === parseInt(formValues?.from_inventory_source))
      const source_to = all_source?.data?.find(source => source?.id === parseInt(formValues?.to_inventory_source))


      setDetails(prev => ({ ...prev, source_from, source_to }))

      if (source_from?.type === 'moh') setUnit(ingredient?.moh_unit?.short_name)
      if (source_from?.type === 'boh') setUnit(ingredient?.boh_unit?.short_name)
    }
  }, [formValues])

  const addEntry = () => {
    const id = Date.now()
    setEntries(prev => [...prev, {
      id,
      "inventory_item_id": null,
      "qty": null,
      "unit": "",
      "unit_price": null,
      "description": 'No description'
    }])
  }
  const deleteEntry = (id) => setEntries(prev => prev.filter(x => x.id !== id))

  const getUnit = useCallback((id) => {
    return items?.data?.find(item => item?.id?.toString() === id)?.boh_unit?.short_name
  }, [items?.data])
  const editEntry = (i, e) => {
    setEntries(prev => {
      let edited = prev
      edited[i] = { ...edited[i], [e.target.name]: e.target.value }
      return edited
    })
  }


  const columns = useMemo(() => ([
    // {
    //   name: 'Item', key: 'item', render: (data, i) => <select className='' name={'inventory_item_id'} onChange={(e) => { editEntry(i, e); }}>
    //     <option value="">Select inventory item</option>
    //     {items?.data?.map(x => <option value={x?.id}>{x?.name}  </option>)
    //     }
    //   </select>
    // },
    {
      name: 'Item', key: 'item', render: (data, i) => <Drop onchange={(e) => { editEntry(i, e); }} name={'inventory_item_id'} placeholder={'Select inventory item'} options={items?.data} />
    },
    // {
    //   name: 'From branch', key: 'from_branch', render: (data, i) => <select className='' name={'inventory_item_id'} onChange={(e) => { editEntry(i, e); }}>
    //     <option value="">Select branch</option>
    //     {selectOpts(branches)?.map(x => <option value={x?.id}>{x?.label}  </option>)
    //     }
    //   </select>
    // },
    {
      name: 'From branch', key: 'from_branch', render: (data, i) => <Drop onchange={(e) => { editEntry(i, e); }} name={'from_branch'} placeholder={'Select branch'} labelKey='label' options={selectOpts(branches)} />
    },
    {
      name: 'From source', key: 'from_source', render: (data, i) => <Drop onchange={(e) => { editEntry(i, e); }} name={'from_source'} placeholder={'Select source'} labelKey='label' options={selectOpts(branches)} />
    },
    { name: 'Fr', key: 'qty', render: (data, i) => <input className='' type='number' name={'qty'} onChange={(e) => editEntry(i, e)} /> },
    { name: 'Qty', key: 'qty', render: (data, i) => <input className='' type='number' name={'qty'} onChange={(e) => editEntry(i, e)} /> },
    { name: 'unit', key: 'unit', render: (data, i) => <input className='outline-none' value={getUnit(data?.inventory_item_id)} key={data?.inventory_item_id} name={'unit'} /> },
    { name: 'Unit price', key: 'unit_price', render: (data, i) => <input className='' type='number' name={'unit_price'} onChange={(e) => editEntry(i, e)} /> },
    { name: 'Total price', key: 'description', render: (data, i) => <input className='' type='number' name={'description'} value={data?.unit_price * data?.qty} onChange={(e) => editEntry(i, e)} />, },
    { name: 'Action', key: 'action', render: (data, i) => <Close className='pointer' onClick={() => deleteEntry(data?.id)} /> },
  ]), [items,branches])

  const submit = async () => {
    setLoading(true)
    try {
      await apiRequest({
        method: 'post',
        url: '/operations/inventory-transfer',
        payload: formValues, hasAuth: true
      })
      toast.success('Transfer successfull')
      dispatch(closeModal())
    } catch (error) {
      console.log(error)
      // throw error
      const err = error.response.data.data.error.message
      if (err) toast.error(err)
    } finally {
      setLoading(false)
    }
  }
  // const One = () => (
  //   <>
  //     <Input label='Select Item' type='select' option={selectOpts(items?.data)} placeholder='Select Item' name={'inventory_item_id'} onChange={handleChange} />


  //     <Box className=' w-[100%]' display={'flex'} gap={'16px'}>
  //       <Input label='From Branch' placeholder='select branch' type='select' name='branch_id_from' option={selectOpts(branches)} onChange={handleChange} />
  //       <Input label='To Branch' placeholder='select branch' type='select' name='branch_id_to' option={selectOpts(branches)} onChange={handleChange} />

  //     </Box>
  //     <Box className='w-[100%]' display={'flex'} gap={'16px'}>
  //       <Input label='From source' type='select' option={selectOpts(source_from?.data)} placeholder='From source' name={'from_inventory_source'} onChange={handleChange} />
  //       <Input label='To source' type='select' option={selectOpts(source_to?.data)} placeholder='To source' name={'to_inventory_source'} onChange={handleChange} />
  //     </Box>
  //     {/* <Input label='Sub-Inventory' placeholder='Fridge, Kitchen' type='select' name={'subInventory'} onChange={handleChange} /> */}
  //     <Input icon={unit} label='Amount ' placeholder='Amount' name={'amount'} onChange={handleChange} />
  //     <Input label='Reasons' placeholder='Add Reasons' name={'reason'} type='textarea' onChange={handleChange} />
  //     <Button onClick={() => setCurrent(1)}>Create transfer</Button>
  //   </>
  // )

  return (
    <div className={`p-3 ${styles.reqInvTran}`}>

      <Header
        name='Create new purchase order'
        text={<>Submit</>}
        buttonOnclick={submit}
      />
      <Form className={'mb-5'}>
        {/* {current === 0 && One()}
        {current === 1 && <ReviewConfirm
          prev={() => setCurrent(0)}
          submitText={loading ? 'Loading...' : 'Submit'}
          next={() => submit()}
        >
          <FlexText leftText={'Item'} rightText={item?.name} />


          <FlexText leftText={'Source branch'} rightText={details?.source_from?.branch?.name} />
          <FlexText leftText={'Source inventory'} rightText={details?.source_from?.name} />

          <FlexText leftText={'Sending quantity'} rightText={`${formValues?.amount} ${unit} `} />

          <FlexText leftText={'Receiving branch'} rightText={details?.source_to?.branch?.name} />
          <FlexText leftText={'Receiving inventory'} rightText={details?.source_to?.name} />
          <FlexText leftText={'Receiving quantity'} rightText={`${item?.boh_to_moh_conversion_rate?.moh * formValues?.amount}  ${unit} `} />


          <FlexText leftText='Reason' rightText={formValues?.reason} />
        </ReviewConfirm>} */}

        {/* {current === 2 && <SuccessfulModal />} */}

        <Input label='Select Date' type='date' placeholder='Select date' name={'inventory_item_id'} className={'!w-50'} onChange={handleChange} />
        <Input label='Reason' type='textarea' placeholder='Reason' name={'inventory_item_id'} className={'!w-50'} onChange={handleChange} />
      </Form>


      <div className={''}>
        <div className={''}>
          {/* <Head /> */}
          {/* {entries[2]?.qty} */}
          <Table
            noPagination
            defaultRowPerpage={100}
            column={columns}
            // url='/operations/purchase-orders'
            data={entries}
            action={false}
          // update={watcher}

          />
          <div className='flex w-full justify-center my-5 '>
            <div className='rounded-[100px] font-bold cursor-pointer flex gap-4 py-2 px-5 items-center bg-[#F7F7F7]' onClick={addEntry}>
              <BiPlus fontWeight={600} /> Add item
            </div>
          </div>
          {/* {<Footer />} */}
        </div>
      </div>
    </div>
  )
}

export default RequestInvTransfer;
