import { Button, CheckInput, Form, Text } from 'Components'
import React, { useEffect, useState } from 'react'
import { changeTitle, closeModal } from 'store/slices/modalSlice'
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { apiRequest } from 'Services';
import { useQuery } from 'react-query';
import { send_otp, validate_otp } from 'Services/otp';


const MakePayment = ({ id, data, customer }) => {
    const [payment, setPayment] = useState('')
    const [screen, setScreen] = useState('select')
    const [otp, setOtp] = useState('');
    const password = 1234
    const dispatch = useDispatch()

    const { data: methods } = useQuery('/collections/payment-methods')
    const pay = async (otp) => {
        try {
            const sendOtp___ = otp ? { otp } : {}
            await apiRequest({
                method: 'post', hasAuth: true,
                url: '/shop/secure/invoices/pay', payload: {
                    "unique_id": data?.invoice_id,...sendOtp___,
                    "payment_method": payment
                }
            })
            // setScreen('success')
            dispatch(closeModal())
        } catch (error) {

        }
    }
    const SelectPay = () => {

        const next = async () => {
            if (payment === 'Be-Hive Wallet') {
                setScreen('otpmessage')

            } else {
                // toast.info('Sorry! payment method not available, use wallet.')
                pay()
            }
        }
        return <Form btnText='Confirm ' onSubmit={next}>
            <div className='flex flex-col justify-start w-full justify-start'>
                {
                    methods?.data?.map(method => <CheckInput label={method} checked={payment === method} onChange={() => setPayment(method)} />)
                }
                {/* <CheckInput label='Card' checked={payment === 'Card'} onChange={() => setPayment('Card')} />
                <CheckInput label='Bank Transfer' checked={payment === 'Bank Transfer'} onChange={() => setPayment('Bank Transfer')} Z />
                <CheckInput label='Be-Hive Wallet' checked={payment === 'Be-Hive Wallet'} onChange={() => setPayment('Be-Hive Wallet')} /> */}

            </div>
        </Form>
    }
    const OtpMessage = () => {
        useEffect(() => {
            changeTitle('Be-Hive Wallet')
        }, [])

        return (
            <div className='w-full  flex gap-3 flex-col justify-center items-center'>
                <Text weight='600' size='16px'>Confirm payment using Be-hive wallet</Text>

                <Text weight='400' size='14px'>
                    We’ll send OTP verification code to customer phone number for verification. Customer will have to send you the code.
                </Text>

                <Button onClick={async () => {
                    try {
                        await send_otp(customer?.phone,'booking')
                        setScreen('otppin')
                    } catch (error) {
                        console.log(error)
                    }
                }}>
                    Make Request
                </Button>

            </div>
        )
    }
    const OtpPin = () => {
        useEffect(() => {
            changeTitle('Be-Hive Wallet')
        }, [])
        const checkPin = async () => {

                try {
                    // await apiRequest({
                    //     url: `/shop/secure/orders/${id}/pay`, payload: {
                    //         unique_id,
                    //         "payment_method": payment
                    //     }, method: 'post', hasAuth: true
                    // })
                    await validate_otp(customer?.phone, otp)
                    // toast.success('O successful')
                    pay(parseInt(otp))

                } catch (error) {
                    // toast.error(error)

                }
            
        }
        return (
            <div className='w-full  flex gap-3 flex-col items-center'>
                <Text weight='600' size='16px'>Enter OTP code sent to customer phone number  </Text>

                <Text weight='400' size='14px'>
                    Please enter the 4-digit code that was sent to the customer phone number to proceed
                </Text>
                <Form onSubmit={checkPin} btnText='Proceed'>
                    <div className=' flex justify-center'>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            inputStyle={{ background: "#EFEFEF", borderRadius: '12px', padding: '14px 0', margin: '0 6px', width: '64px' }}
                            renderSeparator={<span></span>}
                            renderInput={(props) => <input className='!bg-[#EFEFEF] !border p-[14px] hidden ' {...props} />}
                        />
                    </div>
                    {/* <Button onClick={checkPin}>
                        Proceed
                    </Button> */}
                </Form>

            </div>
        )
    }
    const Success = () => {
        useEffect(() => {
            changeTitle('Payment successful')
        }, [])
        return (
            <div className='w-full flex  flex-col gap-3 items-center'>
                <Text weight='600' size='24px'>  </Text>

                <Text weight='400' size='14px'>
                    You payment was successful proccessed
                </Text>
                <Button onClick={() => {
                    dispatch(closeModal())
                }}>
                    Dismiss
                </Button>

            </div>
        )
    }

    return (
        <div className='w-full'>
            {screen === 'select' && <SelectPay />}
            {screen === 'otpmessage' && <OtpMessage />}
            {screen === 'otppin' && <OtpPin />}
            {screen === 'success' && <Success />}

        </div>
    )
}

export default MakePayment