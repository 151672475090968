import { Box } from "@mui/material";
import { BtnStack, FlexText, Table, Text } from "Components";
import ReviewConfirm from "Pages/Operations/Bookings/components/ReviewConfirm";
import ApproveBtns from "Pages/Operations/WorklList/ApproveBtns";
import { useBranches } from "hooks";
import styles from "./../inventorytransfer.module.scss";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { changeTitle, openModal } from "store/slices/modalSlice";
import { Header } from "Partial/Header";
import { BsPlus } from "react-icons/bs";
import dayjs from "dayjs";

const ApproveInvTransferDetails = ({
  submitText,
  next = () => null,
  prev = () => null,
  title = "Review & Confirm",
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  // const { branches } = useBranches()
  const { item_id } = useParams();

  const { data: worklist, refetch } = useQuery(
    `/operations/worklists/${item_id}`,
    { enabled: Boolean(item_id) }
  );

  console.log(worklist?.data);

  useEffect(() => {
    dispatch(changeTitle(title));
  }, [dispatch, title]);
  const previous = () => {
    prev();
  };
  const nextClick = () => {
    next();
  };

  const column2 = [
    { name: "Batch ID", key: "batch_id" },
    {
      name: "Item",
      key: "inventory_item",
      render: (data) => data?.inventory_item?.name,
    },
    { name: "Amount", key: "amount" },
    { name: "Unit", key: "destinationUnit" },
    {
      name: "From source",
      key: "destination_amount",
      render: (data) => data?.from_inventory_source?.name,
    },
    {
      name: "To source",
      key: "destination_amount",
      render: (data) => data?.to_inventory_source?.name,
    },
    { name: "Status", key: "status" },
  ];

  const Head = () => (
    <div className={styles.header}>
      <div>
        <Text size="12px" weight="600" lHeight="18px">
          Date
        </Text>
        <Text size="16px" weight="400" lHeight="24px">
          {dayjs(worklist?.data?.invBatch?.date).format(
            "MMM DD, YYYY. hh:mmA."
          )}
        </Text>
      </div>
      <div>
        <Text size="12px" weight="600" lHeight="18px">
          Reason
        </Text>
        <Text size="16px" weight="400" lHeight="24px">
          {worklist?.data?.invBatch?.reason}
        </Text>
      </div>
      <div>
        <Text size="12px" weight="600" lHeight="18px">
          Transfer ID
        </Text>
        <Text size="16px" weight="400" lHeight="24px">
          {worklist?.data?.invBatch?.special_id}
        </Text>
      </div>
      <div>
        <Text size="12px" weight="600" lHeight="18px">
          Status
        </Text>
        <Text size="16px" weight="400" lHeight="24px">
          {worklist?.data?.invBatch?.status}
        </Text>
      </div>
      <div>
        <Text size="12px" weight="600" lHeight="18px">
          Created by
        </Text>
        <Text size="16px" weight="400" lHeight="24px">
          {worklist?.data?.invBatch?.creator?.first_name +
            " " +
            worklist?.data?.invBatch?.creator?.last_name}
        </Text>
      </div>
    </div>
  );

  return (
    <div className={styles.body}>
      <Header
        name="Approve Inventory Transfer"
        export_name={"inventory-transfer"}
        text={
          item_id ? (
            ""
          ) : (
            <>
              <BsPlus /> Approve Inventory Transfer
            </>
          )
        }
      />
      <div className={styles.container}>
        <>
          <Head />
          <Table
            column={column2}
            data={worklist?.data?.invBatch.inventory_transfers}
            action={false}
          />
        </>
      </div>
      <ApproveBtns />
    </div>
  );
};

export default ApproveInvTransferDetails;
