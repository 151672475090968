import { createSlice } from '@reduxjs/toolkit'
import { v4 } from "uuid";
const initialState = {
    list: [{
        "id": v4(),
        "start_amount": 0,
        "end_amount": 0,
        "fixed_amount": 0
    }]
}

export const dicountSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        addDiscount: (state, { payload }) => {
            // console.log(payload)
            state.list.push({ ...payload, "id": payload?.id || v4()})
        },
        editDiscount: (state, { payload: { i, name, value } }) => {
            // console.log(i, name, value, state.list )
            state.list[i] = { ...state.list[i], [name]: parseInt(value) }
        },
        resetDiscount: (state, { payload: { i, name, value } }) => {
            // console.log(i, name, value, state.list )
            state.list = []
        },
        removeDiscount: (state, { payload }) => {
            // console.log(payload)
            // delete state[payload]
            // return (state.filter(x => x.id !== payload))
            state.list = (state.list.filter((list, i) => list.id !== payload))

        },
    },
})

// Action creators are generated for each case reducer function
export const { addDiscount, removeDiscount, editDiscount, resetDiscount } = dicountSlice.actions

export default dicountSlice.reducer