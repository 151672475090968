import { assets } from 'Assets'
import { Button, EmptyState, Form, Input, Table, Text } from 'Components'
import React from 'react'

const Income = () => {
    const columns = [
        { name: 'First name', key: 'first_name', },
        { name: 'Last name', key: 'last_name', },
        { name: 'Email', key: 'email', },
        { name: 'Phone', key: 'phone', },
        
      ]
      
    return (
        <div className=' flex flex-col gap-4'>

            <div className='px-[40px]'>
            <Form className='border flex justify-around items-center p-4 my-3 rounded-[12px]' direction={'row'}>
                <Input label='Start Date' name='start_date' />
                <Input label='End Date' />
                <Input label="File Type" />
                <Input label="Report Type" />
                <Button className={'h-[100%] !mt-3'}>Generate Report</Button>
            </Form>
            <div className=''>
            <Text weight='700' size='26px'>Income Report</Text>
            </div>
            </div>
            <Table
            column={columns}
            // data={staffs?.data}
            emptyState={<EmptyState icon={assets.icons.staffMagt} text={'There are no report  yet'} />}
            url='admin/staff'
            action={false}
          />
        </div>
    )
}

export default Income