import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    access_token: '',
    isLoggedIn: false,
    refresh_token: '',
    loggedin_at: '', isDefaultPassword: null,
    details: {}
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload: { access_token, refresh_token, user, path, expires_in, isDefaultPassword, aws_session_key } }) => {
            // console.log(user)
            state.access_token = access_token;
            state.refresh_token = refresh_token;
            state.details = user;
            state.isLoggedIn = true;
            state.expires_in = expires_in;
            state.loggedin_at = new Date().getTime();
            state.isDefaultPassword = isDefaultPassword;
            state.aws_session_key = aws_session_key;
            window.location.assign(path)
        },
        updateRoles: (state, {payload}) => {
            // console.log(state.details.roles?.map(role => role?.name))
            // console.log(payload?.map(role => role?.name))
            state.details.roles = payload
        },
        refreshUser: (state, { payload }) => {
            // console.log(payload)
            state.access_token = payload.access_token
            state.refresh_token = payload.refresh_token
            state.details = payload.user
            state.loggedin_at = new Date().getTime();

        },
        // setLoggedinAt:(state)=>{
        //     state.loggedin_at = new Date().getTime();
        // },
        logout: (state) => {
            state.access_token = '';
            state.refresh_token = '';
            state.details = {};
            state.isLoggedIn = false;
            window.location.assign('/login')
        }
    },
})

// Action creators are generated for each case reducer function
export const { setUser, logout, refreshUser, updateRoles } = userSlice.actions

export default userSlice.reducer