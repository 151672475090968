import { Box } from '@mui/material'
import { Text } from 'Components'
import { baseURL } from 'Services'
import React from 'react'

const ItemCard = ({
    img = null,
    toptext,
    bottomtext,
    width = '150px',
    onClick = () => null
}) => {
    
    return (
        <Box
            borderRadius={'8px'}
            width={width}
            bgcolor={'#F7F7F7'}
            padding={'12px'}
            display={'flex'}
            gap={'24px'}
            alignItems={'center'}
            onClick={onClick}
            component={'div'}
           sx={{cursor:"pointer"}}
        >
            {  <Box component={'img'} width={'80px'} src={img ? img : 'https://joadre.com/wp-content/uploads/2019/02/no-image.jpg'} height={'80px'} borderRadius={'8px'} />}
            <Box display={'flex'} flexDirection={'column'}> 
                <Text size='16px' weight='400' lHeight='24px'>{toptext}</Text>
                <Text size='16px' weight='600' lHeight='24px'>{bottomtext}</Text>
                {/* <Text>Text</Text> */}
            </Box>
        </Box >
    )
}

export default ItemCard