import Status from "Components/Status";
import dayjs from "dayjs";

export const tableColumns = [
  {
    key: "updated_at",
    name: "Requested On",
    render: (data) => dayjs(data?.requestedOn).format("MMM DD, YYYY • h:mm A "),
  },
  {
    name: "Booking Number",
    key: "bookingId",
    // render: (data) => dayjs(data?.requestedOn).format("MMM DD YYYY hh:mm A"),
  },
  { key: "tableNo", name: "Table ", render: (data) => data?.table?.name },
  // { key: 'branch', name: 'Branch', render: (data) => data?.branch?.name },
  { key: "customer", name: "Customer", render: (data) => data?.customer?.name },
  {
    key: "scheduled_at",
    name: "Booked Date",
    render: (data) =>
      dayjs(data?.scheduleDate).format("MMM DD, YYYY • h:mm A "),
  },
  {
    key: "status",
    name: "Status",
    render: (data) => <Status data={data}>{data?.status}</Status>,
  },
];
export const venueColumns = [
  { key: "requestedOn", name: "Requested On" },
  {
    name: "Booking Number",
    key: "bookingId",
    // render: (data) => dayjs(data?.requestedOn).format("MMM DD YYYY hh:mm A"),
  },
  { key: "name", name: "Venue Name", render: (data) => data?.venue?.name },
  { key: "customer", name: "Customer", render: (data) => data?.customer?.name },
  {
    key: "event-category",
    name: "Event Category",
    render: (data) => data?.eventCategory?.name,
  },
  {
    key: "startDateTime",
    name: "Start date & time",
    render: (data) =>
      dayjs(`${data?.startDate} ${data?.startTime}`).format(
        "MMM DD, YYYY • h:mm A "
      ),
  },
  {
    key: "endDateTime",
    name: "End date & time",
    render: (data) =>
      dayjs(`${data?.endDate} ${data?.endTime}`).format(
        "MMM DD, YYYY • h:mm A "
      ),
  },
  {
    key: "status",
    name: "Status",
    render: (data) => <Status data={data}>{data?.status}</Status>,
  },
];
export const shortLet = [
  { key: "requestedOn", name: "Requested On" },
  {
    name: "Booking Number",
    key: "bookingId",
    // render: (data) => dayjs(data?.requestedOn).format("MMM DD YYYY hh:mm A"),
  },
  { key: "name", name: "Short let", render: (data) => data?.shortLet?.name },

  { key: "customer", name: "Customer", render: (data) => data?.customer?.name },
  // { key: 'event-category', name: 'Event Category',render:(data) => data?.eventCategory?.name  },
  {
    key: "startDateTime",
    name: "Start date & time",
    render: (data) =>
      dayjs(`${data?.startDate} ${data?.startTime}`).format(
        "MMM DD, YYYY • h:mm A "
      ),
  },

  {
    key: "endDateTime",
    name: "End date & time",
    render: (data) =>
      dayjs(`${data?.endDate} ${data?.endTime}`).format(
        "MMM DD, YYYY • h:mm A "
      ),
  },
  {
    key: "status",
    name: "status",
    render: (data) => <Status data={data}>{data?.status}</Status>,
  },
];
export const tool_equipment = [
  { key: "requestedOn", name: "Requested On" },
  { key: "roomNo", name: "Tool/Equipment Name" },
  { key: "customer", name: "Customer Name" },
  // { key: 'Duration', name: 'Duration (Days)' },
  { key: "startDateTime", name: "Start date & time" },
  { key: "endDateTime", name: "Return date & time" },
  { key: "Payment", name: "Payment" },
];
export const tableData = [{ date: "lkjnbvc" }];
export const columns = {
  tableColumns,
  venueColumns,
  shortLet,
  tool_equipment,
};
