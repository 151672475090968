import { Box } from '@mui/material'
import { Text } from 'Components'
import React, { useEffect } from 'react'
import { formatPrice } from 'utils'

const ListItem = ({ onClick = () => null, price, name, active, id, image }) => {

  // const [selected,setSelected] = useState()

  return (
    <Box sx={{ border: active == id ? 'green 2px solid' : ' ' }} className={`flex justify-between cursor-pointer w-full p-3 items-center ${active == id ? 'bg-[#EFEFEF]' : ''} border border-success rounded`} onClick={() => {
      // setSelected()
      onClick()
    }}>
      <Box className="flex gap-2 items-center"> 
        {image && <Box component={'img'} src={image} width={'40px'} height={'40px'} />}
        <Box>

          <Text size='16px' weight='600' lHeight='24px'>{name} </Text>
          <Text size='16px' weight='600' lHeight='24px'>{price && formatPrice(price)}</Text>
        </Box>
      </Box>
      {/* <Box>
        <Text size='16px' weight='600' lHeight='24px'>{name} </Text>
        <Text size='16px' weight='600' lHeight='24px'>{price && formatPrice(price)}</Text>
      </Box> */}
      <Text size='12px' weight='400' color='#27AE60' lHeight='24px'>Available</Text>
    </Box>
  )
}

export default ListItem