import { apiRequest } from "Services";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { store } from "store";
import { logout, refreshUser } from "store/slices/userSlice";

export const refreshToken = async () => {
  const { refresh_token, details, loggedin_at } = store.getState().user;
  const cognito_id = details?.cognito_id;
  const time_spent = dayjs().diff(loggedin_at, "minutes");

  // console.log('checking session...', time_spent, 'mins')

  if (time_spent > 25 && !window.location.pathname.includes("login")) {
    console.log("Session expired @", window.location.pathname);
    if (cognito_id) {
      try {
        const { data } = await apiRequest({
          url: `/auth/refresh-token`,
          payload: {
            cognito_id,
            refresh_token,
          },
          method: "post",
        });
        if (data?.data) {
          store.dispatch(refreshUser(data?.data));
          console.log(
            "relogin @  ",
            dayjs(store.getState().user.loggedin_at).format(
              "YYYY MMM DD hh:mm:ss"
            )
          );
        } else {
          toast.error("error refreshing token");
          store.dispatch(logout());
        }
      } catch (error) {
        toast.error("error refreshing token");
        store.dispatch(logout());
      }
    } else {
      store.dispatch(logout());
    }
  }

  // if (errMeessage == 'Access Token has expired') {

  // }
};
