import { Box } from '@mui/material'
import { Button, FlexText, Text } from 'Components'
import React from 'react'
import { useDispatch } from 'react-redux'
import { openModal } from 'store/slices/modalSlice'
import Mark from './Mark'
import { formatPrice } from 'utils'

const Details = ({data}) => {
    const dispatch = useDispatch()
    return (
        <>
            <FlexText
                leftText='Date'
                rightText={data?.aged_at}
            />
            <FlexText
                leftText='Transaction ID'
                rightText={data?.unique_id}
            />

            <FlexText
                leftText='Customer name'
                rightText={data?.customer?.full_name}
            />

            <FlexText
                leftText='Phone number'
                rightText={data?.customer?.phone}
            />

            <FlexText
                leftText='Email address'
                rightText={data?.customer?.email}
            />
            <FlexText
                leftText='Order type'
                rightText={data?.order_type?.type}
            />
            <FlexText
                leftText='Amount'
                rightText={formatPrice(data?.total)}
            />
            <Box display={'flex'} width={'100%'} my={'24px'} alignItems={'center'} justifyContent={'space-between'}>
                <Text  >Status</Text>
                <Button  w='fit-content' >{data?.status}</Button>

            </Box>
            <Box display={'flex'} width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
                <Text  >Action</Text>
                <Button radius='24px' w='fit-content' 
        onClick={() => dispatch(openModal({ title: 'Aging receivable', content: <Mark id={data?.id} /> }))}>Mark as paid</Button>
            </Box>
        </>
    )
}

export default Details