
import { Navigate, Route, Routes } from 'react-router';
import { routes } from './routes';
import { BrowserRouter } from 'react-router-dom';
import Layout from './Partial/Layout';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
// import { store, persistor } from './store';
import Modal from './Components/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClientProvider } from 'react-query'
import { queryClient } from './Services/queryClient';
import { store } from 'store';
import { persistor } from 'store';
import React, { Suspense, } from 'react';
import Loader from 'Components/Loader/SuspenseLoader';

import { Notifications } from 'react-push-notification';



function App() {
  const token = store.getState()
  const isLoggedIn = token?.user?.access_token



  const pages = [
    { name: "Administrator", path: '/admin/staff-management', is_allowed: false, role_name: 'administrator' },
    { name: "Operations", path: '/operations', is_allowed: false, role_name: 'operator' },
    { name: "POS Attendant", path: '/pos', is_allowed: false, role_name: 'pos' },
    { name: "Kitchen", path: '/kitchen', is_allowed: false, role_name: 'kitchen' },
    { name: "Waiter", path: '/waiter', is_allowed: false, role_name: 'waiter' },
    { name: "Accounting", path: '/accounting/expenses', is_allowed: false, role_name: 'accountant' },
  ]


  const RunPermit = ({ children, permitkey, path }) => {

    // const navi
    const basepath = path?.split('/')[1]
    const find_role_name_by_base_path = pages.find(page => page.path.includes(basepath))?.role_name
    const roles = store.getState()?.user?.details?.roles?.map(role => role?.name)
    // console.log('roles:=',roles, find_role_name_by_base_path, path, basepath)
    const has_permission_by_role = roles?.find(role => role === find_role_name_by_base_path) || roles?.find(role => role === 'super-admin')


    const isDefaultPassword = (store.getState()?.user?.isDefaultPassword)

    if (isDefaultPassword) {
      toast.info('Please change your password')
      window.location.assign('/change-password')
    }

    if (find_role_name_by_base_path) {
      if (has_permission_by_role) {

        return <>{children}</>
      } else {
        toast.info('You don\'t have permission to this page ')
        setTimeout(() => {
          window.location.assign('/login')
        }, 3000);
        // return <Navigate to={-1} />
      }
    } else {
      return children
    }
    // console.log(basepath)
  }


  return (
    <>

      <ToastContainer />
      <Notifications position='top-right' />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<><Loader /></>}>
            <QueryClientProvider client={queryClient} >
            

              <BrowserRouter>
              <Modal />
                <Routes>
                  {
                    routes.map(({ Component, path, isPublic, layout, permitkey }) => {
                      return <Route key={path}
                        element={
                          isPublic ?
                            <Layout show={layout} >{Component}</Layout>
                            :
                            isLoggedIn ?
                              <RunPermit path={path} permitkey={permitkey}><Layout show={layout} >{Component}</Layout></RunPermit>
                              :
                              <Navigate to={'/login'} />
                        }
                        path={path} />
                    }
                    )
                  }
                </Routes>
              </BrowserRouter>
            </QueryClientProvider>
          </Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
