import React, { useState } from 'react'
import styles from './search.module.scss'
import { Box } from '@mui/material'
import { Form, Input } from 'Components'
import { icons } from 'Assets/icons'

const Search = ({ children, className , onSearch}) => {
    const [show, setShow] = useState(false)
    const toggle = () => {
        
        setShow(!show)
        // console.log(show)
    }
    return (

        <div className={className}>
            <div onClick={toggle}> {children} </div>
            {
                show && <div className={styles.search}>
                    <Form>
                        <Box className={styles.box}>
                            <Input label='' placeholder='search...' onChange={(e)=>onSearch(e.target.value)} />
                        </Box>
                    </Form>
                </div>
            }

        </div>
    )
}

export default Search