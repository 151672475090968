import React from 'react'
import { FlexText } from 'Components'


const WalletDetails = ({ title = 'Wallet Details' }) => {
  return (
      <>
          <FlexText leftText='Date' rightText='man' />
                <FlexText leftText='Customer name' rightText='man' />
                <FlexText leftText='Phone number' rightText='man' />
                <FlexText leftText='Account Number' rightText='man' />
                <FlexText leftText='Amount' rightText='man' />
                <FlexText leftText='Payment Method' rightText='man' />
                <FlexText leftText='Status' rightText='man'/>
      </>
  )
}

export default WalletDetails