import { CheckInput, Form, Text } from 'Components'
import React, { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { clearFilter, setSelectedFilters } from 'store/slices/filterSlice'

// const filters = [
//     { name: '', key: "", list: [{ value: '', name: '' }] }
// ]

const FilterByList = ({
    filters = [
        { name: '', key: "", list: [{ value: '', name: '' }] }
    ],
    onSelect = () => null,
    // selectedFilters
}) => {
    const { filter } = useSelector(state => state)
    const { selectedFilters } = filter
    // console.log(selectedFilters) 
    const dispatch = useDispatch()
    const selectFilter = (filter, value) => {
        const result = { ...selectedFilters, [filter?.key]: value }
        const url = new URLSearchParams(result).toString();
        dispatch(setSelectedFilters(result))
        onSelect(filter, value, result, url)
    }

    const comp = useMemo(() => (
        filters.map((filter, i) => (
            <div className={`w-100 ${filters.length - 1 !== i ? "border-b mb-2" : ''}`}>
                <div className='flex justify-between'>
                    <Text weight='600' size={'12px'}>{filter.name}</Text>
                    <Text weight='400' size={'12px'} onClick={() => dispatch(clearFilter(filter.key))} isLink>Clear</Text>
                </div>
                <div className='overflow-y !w-[300px] !justify-start  !flex flex-wrap bg-[white]'>  {
                    filter?.list?.length ? filter.list
                        ?.map(filter_list_item => <div className=''>
                            <CheckInput checked={selectedFilters[filter.key] === filter_list_item.value} className='' label={filter_list_item.name} onChange={() => selectFilter(filter, filter_list_item.value)} />
                        </div>) : 'Loading items...'
                }</div>
            </div>
        ))
    ), [filters, selectedFilters, selectFilter,clearFilter])

    return (
        <Form className=''>
            <div className='justify-start max-h-[600px] !overflow-y-auto mt-4 p-3 '>
                <Text weight='600' size={'20px'}>Filter by</Text>
                {comp

                }
            </div>
        </Form>
    )
}

export default FilterByList