import React, { useEffect, useState } from 'react'
import styles from './tabs.module.scss'
const Tabs = ({ tabs = [
  { content: 'Existing customers', onClick: () => null, key: 'existing' },
  { content: 'New customers', onClick: () => null, key: 'new' }
], active = 0 }) => {
  const [currentTab, setCurrentTab] = useState(null)
  useEffect(() => {
    setCurrentTab(active)
  }, [active])
  return (
    <div className={styles.container}>
      {tabs.map(({ content, onClick, key }, i) => {
        return (
          <div
            className={`${styles.tab} ${currentTab === key ? styles.active : ' '}`}
            onClick={() => {  onClick(i) }}
          >
            {content} 
          </div>
        )
      }
      )
      }
    </div>
  )
}

export default Tabs