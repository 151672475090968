import { Close } from '@mui/icons-material'
import { assets } from 'Assets'
import { Button, EmptyState, Form, Input, Table, Text } from 'Components'
import { apiRequest } from 'Services'
import dayjs from 'dayjs'
import { useBranches } from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { selectOpts } from 'utils'
import { formatDate } from 'utils/formatPrice'

const InventoryStatement = () => {
  const columns = [
    { name: 'Date', key: 'display_name', render: (data) => formatDate(Date.now()) },
    { name: 'Branch', key: 'branch', },
    { name: 'House Type', key: 'house_type', },
    { name: 'Inventory Source', key: 'house_name', },
  ]
  const [params, setParams] = useState({})
  const [query, setQuery] = useState('')
  const handleChange = (name, value) => setParams(prev => ({ ...prev, [name]: value }))
  useEffect(() => {
    handleChange('date', dayjs(Date.now()).format('YYYY-MM-DD'))
  }, [])
  const generate = async () => {
    const str = (new URLSearchParams(params).toString())
    setQuery(`?${str}`)


    const { data: res } = await apiRequest({ url: `/reports/inventory-items/download/csv?${str}`, hasAuth: true, method: 'get' })
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([res]))
    link.href = url
    const fileName = `Inventory Statement Report ${dayjs(new Date()).format("DD MMM YY")}.csv`;
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }
  const clear = () => {
    setQuery('')
  }
  const { data: report } = useQuery(`/reports/inventory-items${query}`)
  const { data: sources } = useQuery('/admin/inventory-source')

  const { branches } = useBranches()

  const sources_list = useMemo(() => {
    // console.log(sources?.data, params?.inventory_source_branch)
    const res = (sources?.data?.filter(source => (source?.type === params?.with_inventory_source_type && source?.branch?.id === params?.inventory_source_branch)))

    if (params?.with_inventory_source_type) return selectOpts(res)
    if (!params?.with_inventory_source_type) return selectOpts(sources?.data)

  }, [sources?.data, params?.with_inventory_source_type, params?.inventory_source_branch])


  return (
    <div className=' flex flex-col gap-4'>

      <div className='px-[40px]'>
        <Close onClick={clear} />
        <Form className='border flex justify-around items-center p-4 my-3 rounded-[12px]' direction={'row'}>
          <Input placeholder={dayjs().format('MMM DD,YYYY. hh:mmA')} label='Date' name='start_date' type='date' disabled />
          <Input placeholder="Select branch" label='Select branch' name="inventory_source_branch"
            onChange={(e) =>{
              handleChange(e.target.name, e.target.value);
              handleChange('with_inventory_source_type',null)
              handleChange('inventory_source',null)
            }}
            type='select' option={selectOpts(branches)} />
          {/* <Input placeholder="Date" label="File Type" type="select" option={[{ name: 'CSV', value: 'csv' }, { name: 'PDF', value: 'pdf' }]} name/> */}
          <Input placeholder="Select House Type" label="House Type" type="select" onChange={(e) => handleChange(e.target.name, e.target.value)}
            option={[{ label: 'MOH', value: 'moh' }, { label: 'BOH', value: 'boh' }]} name={'with_inventory_source_type'} />

          <Input placeholder="Select Inventory Source" label="Inventory Source" type='select' name={'inventory_source'}
            option={sources_list} onChange={(e) => handleChange(e.target.name, e.target.value)} />

          <Button className={'h-[100%] !mt-3'} onClick={generate}>Generate Report</Button>
        </Form>
        <div className=''>
          <Text weight='700' size='26px'>Inventory Statement Report</Text>
        </div>
      </div>
      <Table
        column={columns}
        data={report?.data?.items}
        emptyState={<EmptyState icon={assets.icons.staffMagt} text={'There are no report  yet'} />}
        // url='/operations/reports/inventory-items'
        action={false}
      />
    </div>
  )
}

export default InventoryStatement