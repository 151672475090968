import React, { useEffect } from "react";
import { FlexText, Text } from "Components";
import { useDispatch } from "react-redux";
import { changeTitle } from "store/slices/modalSlice";
import styles from '../MyCustomers.module.scss'
import { formatPrice } from "utils";
const ViewDatails = ({
  submitText,
  next = () => null,
  prev = () => null,
  title = "View Details",
  user = {}
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeTitle(title));
  }, []);
 console.log(user)
  return (
    <>
      <FlexText leftText="Name" rightText={user?.name} />
      <FlexText leftText="Phone Number" rightText={user?.phone} />
      <FlexText leftText="Email Address" rightText={user?.email} />
      <FlexText leftText="Membership" rightText={user?.membership} />
      <FlexText leftText="Facebook" rightText={user?.facebook ? user?.facebook?.substring(0, 30) + '...' : 'No link'} />
      <FlexText leftText="Instagram" rightText={user?.instagram ? user?.instagram?.substring(0, 30) + '...' : 'No link'} />
      <FlexText leftText="LinkedIn" rightText={user?.linkedin ? user?.linkedin?.substring(0, 30) + '...' : 'No link'} />


      <FlexText leftText="Account number" rightText={user?.account_number ? user?.account_number : 'Not assigned'} />
      <FlexText leftText="Balance" rightText={"₦" + (user?.balance ? user?.balance?.toLocaleString() : '0')} />
      <FlexText leftText="Available purchase limits" rightText={formatPrice(user?.available_purchase_limits)} />
      <FlexText leftText="Customer status" rightText={user?.status} />

      <div className={styles.box}>
        <Text weight="600" size="12px" color="#27AE60">{user?.discount === null? 0: user?.discount?.data?.discount}{user?.discount?.data?.discount_is_fixed ? <>&#8358;</> : '%'}    Discount</Text>
      </div>

      <div className={styles.box}>

      <Text weight="600" size="12px" color="#FF0030">  {formatPrice(user?.overdraft_limit)} Overdraft limit</Text>
      </div>
      {/* <BtnStack leftClick={previous} rightClick={nextClick} /> */}
    </>
  );
};

export default ViewDatails;
