import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  name: "",
  price: 0,
  vip_price: 0,
  duration: 0,
  category_id: "",
  image: {},
  imgUrl: "",
  ingredients: [
    // { id: '', inventory_item_id: undefined, unit_id: '', quantity: '' }
  ],
};

export const menuItemSlice = createSlice({
  name: "menuItem",
  initialState,
  reducers: {
    setInitialMenu: (state, { payload }) => {
      // console.log(payload)
      state.id = payload?.id;
      state.name = payload?.name;
      state.price = payload?.price;
      state.vip_price = payload?.vip_price;
      state.duration = payload?.duration?.replace(/\D/g, "");
      state.branch_id = payload?.branch?.id;
      state.cost_account = payload?.cost_account?.id;
      state.cost_account_id = payload?.cost_account?.id;
      state.sale_account_id = payload?.sale_account?.id;
      state.category_id = payload?.category?.id;
      state.inventory_source_id = payload?.inventory_source?.id;
      state.imgUrl = payload?.image;
      state.status = payload?.status || "Active";
      // state.items = payload?.items

      state.ingredients = [];
      // console.log(payload?.ingredients)
      Object.values(payload?.ingredients || {})?.map(
        ({ id, quantity, unit, name, inventory_item_id }, i) => {
          state.ingredients[i] = {
            id: id,
            inventory_item_id,
            name,
            quantity,
            unit_id: unit?.id,
            ...state.ingredients[i],
          };
        }
      );
      // return state÷÷
    },
    setItem: (state, { payload: { name, value } }) => {
      state[name] = value;
      if (name === "duration") state[name] = value?.replace(/\D/g, "");
    },
    setIngredient: (state, { payload: { name, value, i } }) => {
      // console.log(name, value, i)
      state.ingredients[i] = { ...state.ingredients[i], [name]: value };
    },
    addItem: (state, { payload }) => {
      state.ingredients = [
        ...state.ingredients,
        { id: "", quantity: 0, unit_id: "" },
      ];
    },
    removeIngredient: (state, { payload: { index, removeByid } }) => {
      if (state.ingredients.length !== 1) {
        state.ingredients = state.ingredients.filter(
          (ingredient, i) => i !== index
        );
      } else {
        toast.info("Menu item must have an ingredient");
      }
    },
    clearMenu: (state) => {
      state.ingredients = [
        { id: "", unit_id: "", quantity: 0, inventory_item_id: "" },
      ];
      state.duration = 0;
      state.name = "";
      state.price = "";
      state.vip_price = "";
      state.imgUrl = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setItem,
  setIngredient,
  addItem,
  removeIngredient,
  setInitialMenu,
  clearMenu,
} = menuItemSlice.actions;

export default menuItemSlice.reducer;
