import { Close } from "@mui/icons-material";
import { assets } from "Assets";
import { Button, EmptyState, Form, Input, Table, Text } from "Components";
import { apiRequest } from "Services";
import dayjs from "dayjs";
import { useBranches } from "hooks";
import React, { useEffect, useState } from "react";
import { BiChevronsUp } from "react-icons/bi";
import { useQuery } from "react-query";
import { selectOpts } from "utils";
import { formatDate } from "utils/formatPrice";

const SaleMargin = () => {
  const overviewColumn = [
    { name: "Item", key: "item_name" },
    { name: "Branch", key: "branch" },
    { name: "Quantity", key: "total_quantity" },
    { name: "Total Margin", key: "total_gross_margin_total" },
  ];

  const subColumn = [
    { name: "Customer Name", key: "customer_name" },
    { name: "Branch", key: "branch" },
    { name: "Invoice id", key: "invoice_id" },
    { name: "Booking id", key: "booking_id" },
    { name: "Category", key: "category" },
    { name: "Amount", key: "amount" },
    {
      name: "Date",
      key: "date",
      render: (data) => dayjs(data?.date).format("MMM DD, YYYY. hh:mm A"),
    },
  ];

  const detailedColumn = [
    {
      name: "Date",
      key: "created_at",
      render: (data) => dayjs(data?.created_at).format("MMM DD, YYYY. hh:mm A"),
    },
    { name: "Branch", key: "branch" },
    { name: "Order Id", key: "order_id" },
    { name: "Item", key: "item_name" },
    { name: "Quantity", key: "item_quantity" },
    { name: "Waiter", key: "created_by" },
    { name: "Total Margin", key: "gross_margin_total" },
    { name: "STC", key: "state_tax_amount" },
    { name: "SEC", key: "service_charge_amount" },
    { name: "VAT", key: "vat_amount" },
  ];

  const [params, setParams] = useState({ sort: "desc,created_at" });
  const [query, setQuery] = useState("");
  const [dates, setDates] = useState([]);
  const [view, setView] = useState(1);
  const [viewSbu, setSbuView] = useState(1);

  //     Date
  // Order ID
  // Item
  // Qty
  // Total Margin
  const handleView = (name, value) => setView(value);
  const handleSbu = (name, value) => setSbuView(value);

  const handleChange = (name, value) =>
    setParams((prev) => ({ ...prev, [name]: value }));
  useEffect(() => {
    handleChange("date", dayjs(Date.now()).format("YYYY-MM-DD"));
  }, []);
  const generate = async () => {
    const str = new URLSearchParams(params).toString();
    setQuery(`?${str}`);

    const { data: res } = await apiRequest({
      url: `/reports/sales-margin/csv?${str}`,
      hasAuth: true,
      method: "get",
    });

    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([res]));
    link.href = url;
    const fileName = `Sales Margin Report ${dayjs(new Date()).format(
      "DD MMM YY"
    )}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  useEffect(() => {
    const str = new URLSearchParams(params).toString();
    setQuery(`?${str}`);
  }, [params]);
  const clear = () => {
    setQuery("");
  };
  const { data: report } = useQuery(`/reports/sales-margin${query}`);
  const { data: overviewReport } = useQuery(
    `/reports/sales-margin-grouped${query}`
  );
  const { data: subOthers } = useQuery(`/reports/sales-margin-sub${query}`);
  // const { data: sources } = useQuery('/admin/inventory-source')

  const { branches } = useBranches();
  const repotyView = [
    { name: "Detailed", id: 1 },
    { name: "Overview", id: 2 },
  ];

  const sbu = [
    { name: "Resturant and food ordering", id: 1 },
    { name: "Hospitality and Studio managment", id: 2 },
  ];

  const handleDates = (e) => {
    const index = e.target.name;
    const date = dayjs(e.target.value).format("YYYY-MM-DD");
    setDates((prev) => {
      let values = prev;
      values[index] = date;
      return values;
    });
    handleChange("created_at[btw]", JSON.stringify(dates));
  };

  return (
    <div className=" flex flex-col gap-4">
      <div className="px-[40px]">
        <Close onClick={clear} />
        <Form
          className="border flex justify-around items-center p-4 my-3 rounded-[12px]"
          direction={"row"}
        >
          <Input
            placeholder="From"
            label="From (date and time)"
            name="0"
            type="date-time"
            onChange={handleDates}
          />
          <Input
            placeholder="To"
            label="To (date and time)"
            name="1"
            type="date-time"
            onChange={handleDates}
          />
          <Input
            placeholder="Select branch"
            label="Select branch"
            name="branch_id"
            onChange={(e) =>
              handleChange(e.target.name, parseInt(e.target.value))
            }
            type="select"
            option={selectOpts(branches, "name", "id")}
          />

          <Input
            placeholder="Select view"
            label="Select view"
            name="view_id"
            onChange={(e) =>
              handleView(e.target.name, parseInt(e.target.value))
            }
            type="select"
            option={selectOpts(repotyView, "name", "id")}
          />
          <Input
            placeholder="SBU"
            label="Bussiness unit"
            name="view_id"
            onChange={(e) => handleSbu(e.target.name, parseInt(e.target.value))}
            type="select"
            option={selectOpts(sbu, "name", "id")}
          />
          {/* <Input placeholder="Date" label="File Type" type="select" option={[{ name: 'CSV', value: 'csv' }, { name: 'PDF', value: 'pdf' }]} name/> */}
          {/* <Input placeholder="Select House Type" label="House Type" type="select" onChange={(e) => handleChange(e.target.name, e.target.value)}
            option={[{ label: 'MOH', value: 'moh' }, { label: 'BOH', value: 'boh' }]} name={'with_inventory_source_type'} /> */}
          {/* <Input placeholder="Select Inventory Source" label="Inventory Source" type='select' name={'inventory_source'}
            option={selectOpts(sources?.data)} onChange={(e) => handleChange(e.target.name, e.target.value)} /> */}
          <Button className={"h-[100%] !mt-3"} onClick={generate}>
            Generate Report
          </Button>
        </Form>
        <div className="w-full h-[250px] flex flex-col gap-2">
          <div className="w-full h-[90%] flex gap-5 justify-between items-center">
            {/* sales card  */}
            <div className="w-[20%] h-[90%] flex border-2 rounded-lg p-2">
              <div className="w-[40%] h-full  flex justify-center items-center">
                <img src="/images/Money4.png" width={50} height={50} alt="" />
              </div>
              <div className="w-[60%] h-full flex gap-3 justify-center items-left flex-col">
                <Text weight="500" size="16px">
                  Total sales
                </Text>
                <Text weight="700" size="20px">
                  ₦ {overviewReport?.data?.total_price}
                </Text>
                <div className="flex gap-2 justify-start items-center">
                  <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center bg-green-200">
                    <BiChevronsUp />
                  </div>
                  <Text weight="100" size="10px">
                    25%
                  </Text>
                </div>
              </div>
            </div>
            {/* order */}
            <div className="w-[20%] h-[90%] flex border-2 rounded-lg p-2">
              <div className="w-[40%] h-full  flex justify-center items-center">
                <img
                  src="/images/Total order 4.png"
                  width={50}
                  height={50}
                  alt=""
                />
              </div>
              <div className="w-[60%] h-full flex gap-3 justify-center items-left flex-col">
                <Text weight="500" size="16px">
                  Total Orders
                </Text>
                <Text weight="700" size="20px">
                  {overviewReport?.data?.total_order}
                </Text>
                <div className="flex gap-2 justify-start items-center">
                  <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center bg-green-200">
                    <BiChevronsUp />
                  </div>
                  <Text weight="100" size="10px">
                    10%
                  </Text>
                </div>
              </div>
            </div>
            {/* pendingg */}
            <div className="w-[20%] h-[90%] flex border-2 rounded-lg p-2">
              <div className="w-[40%] h-full  flex justify-center items-center">
                <img src="/images/Drink4.png" width={50} height={50} alt="" />
              </div>
              <div className="w-[60%] h-full flex gap-3 justify-center items-left flex-col">
                <Text weight="500" size="16px">
                  Pending Orders
                </Text>
                <Text weight="700" size="20px">
                  {overviewReport?.data?.pending_orders}
                </Text>
                <div className="flex gap-2 justify-start items-center">
                  <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center bg-green-200">
                    <BiChevronsUp />
                  </div>
                  <Text weight="100" size="10px">
                    15%
                  </Text>
                </div>
              </div>
            </div>
            {/* completed */}
            <div className="w-[20%] h-[90%] flex border-2 rounded-lg p-2">
              <div className="w-[40%] h-full  flex justify-center items-center">
                <img src="/images/Food4.png" width={50} height={50} alt="" />
              </div>
              <div className="w-[60%] h-full flex gap-3 justify-center items-left flex-col">
                <Text weight="500" size="16px">
                  Completed Orders
                </Text>
                <Text weight="700" size="20px">
                  {overviewReport?.data?.completed_orders}
                </Text>
                <div className="flex gap-2 justify-start items-center">
                  <div className="w-[20px] h-[20px] rounded-full flex justify-center items-center bg-green-200">
                    <BiChevronsUp />
                  </div>
                  <Text weight="100" size="10px">
                    17%
                  </Text>
                </div>
              </div>
            </div>
          </div>
          <span className="flex flex-col gap-3">
            <Text weight="700" size="26px">
              Sale Margin Report
            </Text>
            <Text weight="300" size="15px">
              {viewSbu === 1
                ? "(Resturant and food ordering)"
                : "(Hospitality and Studio managment)"}
            </Text>
          </span>
        </div>
      </div>
      {viewSbu === 2 ? (
        view === 1 && (
          <Table
            column={subColumn}
            data={subOthers?.data?.sales_margins}
            emptyState={
              <EmptyState
                icon={assets.icons.staffMagt}
                text={"There are no reports yet"}
              />
            }
            action={false}
          />
        )
      ) : view === 1 ? (
        <Table
          column={detailedColumn}
          data={report?.data?.sales_margins}
          emptyState={
            <EmptyState
              icon={assets.icons.staffMagt}
              text={"There are no reports yet"}
            />
          }
          action={false}
        />
      ) : (
        <Table
          column={overviewColumn}
          data={overviewReport?.data?.sales_margins}
          emptyState={
            <EmptyState
              icon={assets.icons.staffMagt}
              text={"There are no reports yet"}
            />
          }
          action={false}
        />
      )}
    </div>
  );
};

export default SaleMargin;
