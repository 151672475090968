import styles from './button.module.scss'
import { Button } from '.'
import { BiFilter, BiExport, BiSearch, BiX } from 'react-icons/bi'
import { useMediaQuery } from '@mui/material'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useId, useMemo, useState } from 'react'

import { useDispatch } from 'react-redux'
import { searchList } from 'store/slices/listSlice'

import { downloadList } from 'utils'
import { useSelector } from 'react-redux'
import { clearFilters } from 'store/slices/filterSlice'

const SearchComponent = (props) => {
    const { search: searchValue } = useSelector(state => state.list)

    const dispatch = useDispatch()
    return (<div className={'fixed flex items-center top-0 left-[50%] !w-[500px] border'}>
        {/* <Input placeholder='search...' label='' key={'xzzff'} name={'xyzsearch'} onChange={(e) => {
            dispatch(searchList(e.target.value));
            props.onSearch(e.target.value)
        }} /> */}
        <input type="search" autoFocus value={searchValue}
            className='p-2 border bg-[#EFEFEF] mt-2 rounded outline-none !w-[100%]' onChange={(e) => dispatch(searchList(e.target.value))} />
    </div>)
}


export const ButtonGroup = ({
    group = [
        {
            icon: <BiExport fontSize={'25px'} />, onClick: () => null
        },
        {
            icon: <BiSearch fontSize={'25px'} />, onClick: () => null
        },
        {
            icon: <BiFilter fontSize={'25px'} />, onClick: () => null
        }
    ], export_name, onExport, filterComponent, onCloseFilter = () => null, onSearch,
},) => {
    const reduce = useMediaQuery('(max-width:600px)')
    const dispatch = useDispatch()
    // const id = useId()
    const [show, setShow] = useState(false)
    const [searchBoxOpened, setSearchBoxOpened] = useState(false)
    const runShow = () => {
        if (reduce) {
            setShow(prev => !prev)
        }
    }
    // const downloadables = [
    //     'inventory_items',
    //     'staffs',
    //     'orders',
    //     'booked-venues',
    //     'roles',
    //     'branches',
    //     'tables',
    //     'pos',
    //     'discounts',
    //     'menus',
    //     'menu-categories',
    //     'menu',
    //     'packages',
    //     'ingredients',
    //     'venues',
    //     'account-types',
    //     'accounts',
    //     'sbu-codes',
    //     'suppliers',
    //     'order-types',
    //     'table-bookings',
    //     'operation-InventoryItem',
    //     'customers',
    //     'assets',
    // ]


    const [showFilter, setShowFilter] = useState(false)

    const Component = () => useMemo(() => (<div className={styles.buttonGroup}>

        {
            export_name && <Button textSize='24px' shadow={false} radius='0' bgcolor='inherit' color='black' onClick={() => {
                if (onExport) {
                    onExport()
                } else {
                    downloadList(export_name)
                }
            }} key={Math.random()}>
                <BiExport fontSize={'25px'} />
            </Button>
        }
        <Button textSize='24px' shadow={false} radius='0' bgcolor='inherit' color='black' key={Math.random()}>
            <BiSearch fontSize={'25px'} onClick={() => setSearchBoxOpened(prev => !prev)} />
            {searchBoxOpened && <SearchComponent onSearch={onSearch} />}
        </Button>
        {filterComponent && <Button textSize='24px' shadow={false} radius='0' onClick={() => setShowFilter(true)} bgcolor='inherit' color='black' key={Math.random()}>
            <BiFilter fontSize={'25px'} />
        </Button>}

        {showFilter &&
            <div className='fixed border bg-white !z-[10000] shadow right-[220px] top-[120px]'>
                <BiX onClick={() => {
                    onCloseFilter()
                    // dispatch(clearFilters())
                    setShowFilter(false)
                }} className='absolute float-right  z-100 cursor-pointer' />
                {filterComponent}
            </div>
        }
    </div >), [])
    return !reduce ? <Component /> : <div><GiHamburgerMenu onClick={runShow} /> {show && <Component />} </div>

}