import React from 'react'
import styles from './emptystate.module.scss'
import { Text } from '../Text'
export const EmptyState = ({ icon , text}) => {
    return (
        <div className={styles.emptystate}>
            <div className={styles.center}>
                <div className={styles.iconCircle}>
                    {icon}
                </div>
                <Text>{text}</Text>
            </div>
        </div>

    )
}