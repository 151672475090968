import React, { useState } from 'react'
import { EmptyState, Button, Table, DeleteModal } from '../../../Components'
import { assets } from '../../../Assets'
import { Header } from '../../../Partial/Header'

import { useDispatch } from 'react-redux'
import { formatDate } from 'utils/formatPrice'
import dayjs from 'dayjs'
import FilterByList from 'Components/Button/FilterByList'

const OTPs = () => {
  const [state] = useState(false)
  const dispatch = useDispatch()
  // const [ordertype,] = useState([
  //   { orderType: 'Delivery', description: 'Ughelli', branchURL: 'description of the order type' },
  //   { orderType: 'Delivery', description: 'Ughelli', branchURL: 'description of the order type' },
  //   { orderType: 'Delivery', description: 'Ughelli', branchURL: 'description of the order type' },
  // ])
  const columns = [
    { name: 'Date', key: 'date', render:(data)=>dayjs(data?.date).format('MMM DD, YYYY. hh:mm A')},
    { name: 'Phone', key: 'phone', },
    { name: 'Code', key: 'code', },
    { name: 'Status', key: 'status', },
  ]
  const [selectedFilters, setSelectedFilters] = useState({})
  const filters = [

    // { name: 'Branches', key: 'branch_id', list: branches?.map(code => ({ name: code?.name, value: code?.id })) },
    // { name: 'Venues', key: 'venue_id', list: venues?.map(code => ({ name: code?.name, value: code?.id })) },
    // { name: 'Status', key: 'status', list: ['Available', 'Booked'].map(item => ({ name: item, value: item })) },
    { name: 'Status', key: 'status', list: ['Completed', 'Pending'].map(item => ({ name: item, value: item })) },
  ]
  return (
    <>
    <Header name={'Sent OTPs'}
     onCloseFilter={() => setSelectedFilters({})}
     filterComponent={
       <FilterByList filters={filters}
         onSelect={(data, value, selectedFilters) => setSelectedFilters(selectedFilters)} selectedFilters={selectedFilters}
       />}
    //  text={'Add Order Type'}
    //  buttonOnclick={() => dispatch(openModal({ title: 'Add order type', content: <AddOTPs /> }))}
    />
    {
      state ?
      <EmptyState
        icon = { assets.icons.orderType}
        text={'This is currently empty'}
      />
      :
      <Table
      column={columns}
      // data={ordertype}
      action={false}
      url='admin/otp'
      useRandomkey
      
      />
    }

    </>
  )
}

export default OTPs