

export const operations_icons ={
    dashboard:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4 10.9998H10C10.2652 10.9998 10.5196 10.8944 10.7071 10.7069C10.8946 10.5193 11 10.265 11 9.99978V3.99978C11 3.73456 10.8946 3.4802 10.7071 3.29267C10.5196 3.10513 10.2652 2.99978 10 2.99978H4C3.73478 2.99978 3.48043 3.10513 3.29289 3.29267C3.10536 3.4802 3 3.73456 3 3.99978V9.99978C3 10.265 3.10536 10.5193 3.29289 10.7069C3.48043 10.8944 3.73478 10.9998 4 10.9998ZM4 20.9998H10C10.2652 20.9998 10.5196 20.8944 10.7071 20.7069C10.8946 20.5193 11 20.265 11 19.9998V13.9998C11 13.7346 10.8946 13.4802 10.7071 13.2927C10.5196 13.1051 10.2652 12.9998 10 12.9998H4C3.73478 12.9998 3.48043 13.1051 3.29289 13.2927C3.10536 13.4802 3 13.7346 3 13.9998V19.9998C3 20.265 3.10536 20.5193 3.29289 20.7069C3.48043 20.8944 3.73478 20.9998 4 20.9998ZM14 20.9998H20C20.2652 20.9998 20.5196 20.8944 20.7071 20.7069C20.8946 20.5193 21 20.265 21 19.9998V13.9998C21 13.7346 20.8946 13.4802 20.7071 13.2927C20.5196 13.1051 20.2652 12.9998 20 12.9998H14C13.7348 12.9998 13.4804 13.1051 13.2929 13.2927C13.1054 13.4802 13 13.7346 13 13.9998V19.9998C13 20.265 13.1054 20.5193 13.2929 20.7069C13.4804 20.8944 13.7348 20.9998 14 20.9998ZM21.293 6.29278L17.707 2.70678C17.6142 2.61383 17.504 2.54009 17.3827 2.48978C17.2614 2.43947 17.1313 2.41357 17 2.41357C16.8687 2.41357 16.7386 2.43947 16.6173 2.48978C16.496 2.54009 16.3858 2.61383 16.293 2.70678L12.707 6.29278C12.6141 6.38556 12.5403 6.49577 12.49 6.61708C12.4397 6.7384 12.4138 6.86844 12.4138 6.99978C12.4138 7.13111 12.4397 7.26115 12.49 7.38247C12.5403 7.50378 12.6141 7.61399 12.707 7.70678L16.293 11.2928C16.3858 11.3857 16.496 11.4595 16.6173 11.5098C16.7386 11.5601 16.8687 11.586 17 11.586C17.1313 11.586 17.2614 11.5601 17.3827 11.5098C17.504 11.4595 17.6142 11.3857 17.707 11.2928L21.293 7.70678C21.3859 7.61399 21.4597 7.50378 21.51 7.38247C21.5603 7.26115 21.5862 7.13111 21.5862 6.99978C21.5862 6.86844 21.5603 6.7384 21.51 6.61708C21.4597 6.49577 21.3859 6.38556 21.293 6.29278Z" fill="#4F4F4F"/>
  </svg>,
    worklist  : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M4 6H6V8H4V6ZM4 11H6V13H4V11ZM4 16H6V18H4V16ZM20 8V6H8.023V8H18.8H20ZM8 11H20V13H8V11ZM8 16H20V18H8V16Z" fill="#4F4F4F"/>
  </svg>,
  booking:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M21 20V6C21 4.897 20.103 4 19 4H17V2H15V4H9V2H7V4H5C3.897 4 3 4.897 3 6V20C3 21.103 3.897 22 5 22H19C20.103 22 21 21.103 21 20ZM9 18H7V16H9V18ZM9 14H7V12H9V14ZM13 18H11V16H13V18ZM13 14H11V12H13V14ZM17 18H15V16H17V18ZM17 14H15V12H17V14ZM19 9H5V7H19V9Z" fill="#4F4F4F"/>
</svg>,
my_customers:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M9.5 12C11.706 12 13.5 10.206 13.5 7.99997C13.5 5.79397 11.706 3.99997 9.5 3.99997C7.294 3.99997 5.5 5.79397 5.5 7.99997C5.5 10.206 7.294 12 9.5 12ZM11 13H8C4.691 13 2 15.691 2 19V20H17V19C17 15.691 14.309 13 11 13Z" fill="#4F4F4F"/>
<path d="M16.604 11.048C17.213 10.0101 17.4761 8.80528 17.355 7.60797C17.176 5.82397 16.18 4.24697 14.552 3.16797L13.447 4.83397C14.566 5.57597 15.247 6.63297 15.365 7.80797C15.4194 8.35407 15.3514 8.90543 15.166 9.42195C14.9806 9.93847 14.6823 10.4072 14.293 10.794L13.101 11.986L14.719 12.461C18.951 13.701 19 17.957 19 18H21C21 16.211 20.044 12.715 16.604 11.048Z" fill="#4F4F4F"/>
</svg>,
orders:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M21 15C21 10.375 17.493 6.559 13 6.059V4H11V6.059C6.507 6.559 3 10.375 3 15V17H21V15ZM2 18H22V20H2V18Z" fill="#4F4F4F"/>
</svg>,
inven_mgnt:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6 8V4H3.993C3.445 4 3 4.445 3 4.993V21.007C3.00026 21.2703 3.10497 21.5227 3.29114 21.7089C3.4773 21.895 3.72972 21.9997 3.993 22H20.007C20.2703 21.9997 20.5227 21.895 20.7089 21.7089C20.895 21.5227 20.9997 21.2703 21 21.007V4.993C21 4.445 20.555 4 20.007 4H18V8H6ZM16 3C16 2.44772 15.5523 2 15 2H9C8.44772 2 8 2.44772 8 3V6H16V3ZM10.4851 19.1209L7 15.6358L8.414 14.2218L10.4851 16.2929L15.3641 11.4139L16.7781 12.8279L10.4851 19.1209Z" fill="#4F4F4F"/>
</svg>,
shift_mgnt:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M3.875 13.2399C3.875 14.7361 5.00375 15.8649 6.5 15.8649C7.99625 15.8649 9.125 14.7361 9.125 13.2399C9.125 11.7436 7.99625 10.6149 6.5 10.6149C5.00375 10.6149 3.875 11.7436 3.875 13.2399ZM3.5 21.0759H11V20.3259C11 18.2581 9.31775 16.5759 7.25 16.5759H5.75C3.68225 16.5759 2 18.2581 2 20.3259V21.0759H3.5Z" fill="#4F4F4F"/>
<path d="M19.2774 6.15056L15.6787 3.08482C15.3465 2.8633 14.9014 3.10149 14.9014 3.50084L14.9014 5.56653H8.90137L8.90135 3.50084C8.90135 3.10149 8.45627 2.8633 8.124 3.08482L4.52538 6.15056C4.22852 6.34847 4.22852 6.7847 4.52538 6.98261L8.124 10.0483C8.45627 10.2699 8.90135 10.0317 8.90135 9.63232L8.90137 7.56653H14.9014L14.9014 9.63232C14.9014 10.0317 15.3465 10.2699 15.6787 10.0483L19.2774 6.98261C19.5742 6.7847 19.5742 6.34847 19.2774 6.15056Z" fill="#4F4F4F"/>
<path d="M14.875 13.2399C14.875 14.7361 16.0037 15.8649 17.5 15.8649C18.9963 15.8649 20.125 14.7361 20.125 13.2399C20.125 11.7436 18.9963 10.6149 17.5 10.6149C16.0037 10.6149 14.875 11.7436 14.875 13.2399ZM14.5 21.0759H22V20.3259C22 18.2581 20.3178 16.5759 18.25 16.5759H16.75C14.6823 16.5759 13 18.2581 13 20.3259V21.0759H14.5Z" fill="#4F4F4F"/>
</svg>,
reports:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20 12V18C20 18.2652 19.8946 18.5196 19.7071 18.7071C19.5196 18.8946 19.2652 19 19 19C18.7348 19 18.4804 18.8946 18.2929 18.7071C18.1054 18.5196 18 18.2652 18 18V4C18 3.73478 17.8946 3.48043 17.7071 3.29289C17.5196 3.10536 17.2652 3 17 3H3C2.73478 3 2.48043 3.10536 2.29289 3.29289C2.10536 3.48043 2 3.73478 2 4V18C2 19.654 3.346 21 5 21H19C20.654 21 22 19.654 22 18V12H20ZM14 11V13H6V11H14ZM6 9V7H14V9H6ZM14 15V17H11V15H14Z" fill="#4F4F4F"/>
</svg>,
aging_receivable:<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M20.817 11.186C20.5814 10.0344 20.1209 8.94048 19.462 7.967C18.8146 7.00928 17.9897 6.18442 17.032 5.537C16.0584 4.87833 14.9646 4.41789 13.813 4.182C13.2081 4.05933 12.5922 3.99901 11.975 4.002V2L8 5L11.975 8V6.002C12.459 6 12.943 6.046 13.41 6.142C14.305 6.32541 15.1552 6.68321 15.912 7.195C16.6584 7.69824 17.3008 8.34063 17.804 9.087C18.5853 10.2422 19.002 11.6054 19 13C18.9998 13.9359 18.8128 14.8623 18.45 15.725C18.2735 16.1405 18.0579 16.5383 17.806 16.913C17.5531 17.2854 17.2659 17.6332 16.948 17.952C15.98 18.9182 14.7511 19.5809 13.412 19.859C12.4807 20.047 11.5213 20.047 10.59 19.859C9.69456 19.6754 8.84404 19.3173 8.087 18.805C7.34148 18.3022 6.6998 17.6605 6.197 16.915C5.41656 15.7585 4.9997 14.3952 5 13H3C3.00106 14.7937 3.53689 16.5463 4.539 18.034C5.18685 18.9901 6.01086 19.8142 6.967 20.462C8.45262 21.4675 10.2061 22.0033 12 22C12.6093 21.9999 13.217 21.9386 13.814 21.817C14.9647 21.5794 16.0579 21.1191 17.032 20.462C17.5103 20.1397 17.956 19.7717 18.363 19.363C18.7705 18.9544 19.1388 18.5084 19.463 18.031C20.4676 16.5458 21.0031 14.7931 21 13C20.9999 12.3907 20.9386 11.783 20.817 11.186Z" fill="#4F4F4F"/>
<path d="M12 16C13.671 16 15 14.669 15 13C15 11.331 13.671 10 12 10C10.329 10 9 11.331 9 13C9 14.669 10.329 16 12 16Z" fill="#4F4F4F"/>
</svg>
}