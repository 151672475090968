import React from 'react'
import styles from './modal.module.scss'
import { Text } from '../Text'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../../store/slices/modalSlice'
const Modal = () => {
    const { title, content, isOpen } = useSelector(state => state.modal)
    const dispatch = useDispatch()
    // useEffect(() => { console.log(isOpen) }, [isOpen])

    return (
        isOpen && <>
            <div className={styles.clickaway} onClick={() => dispatch(closeModal())}></div>
            <div className={styles.modal}>
                <div className={styles.title}>
                    <Text weight='600' lHeight='36px' size='24px' align='center'>{title}</Text></div>
                {content}
            </div>
        </>
    )
}

export default Modal;