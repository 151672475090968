import { Box } from '@mui/material'
import { Button} from 'Components'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { changeTitle, closeModal } from 'store/slices/modalSlice'

const SuccessfulModal = ({ btnText = 'Dismiss', message = '' , title =  'Hive Wallet funded successfully', }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(changeTitle(title))
    }, [])
    return (
        <Box display={'flex'} flexDirection={'column'} gap='16px' alignItems={'center'} >
            {/* <Text size='24px' lHeight='36px' weight='600' align='center' w='100%'>{title}</Text> */}
            
            <Box mt={'24px'}>

            </Box>

            <Button onClick={()=>dispatch(closeModal())}> Dismiss</Button>
        </Box>
    )
}

export default SuccessfulModal