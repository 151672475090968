import React, { useState } from 'react';
import styles from './Bookings.module.scss';
import { Header } from 'Partial/Header';
import { BiPlus } from 'react-icons/bi';
import { Button, DeleteModal, Table, TopButtons } from 'Components';
import { Box } from '@mui/material';
import { columns } from './utils';
import { openModal } from 'store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { icons } from 'Assets/icons';
import ReserveTable from './components/ReserveTable';
import { useQuery } from 'react-query';
import FilterByList from 'Components/Button/FilterByList';
// import { useNavigate } from 'react-router';
const PosReserveTable = () => {

  const [currentTable, ] = useState({ column: columns.tableColumns, data: [], key: 'table', })
  const  [stat,setSTAT]=useState('Active')
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const {data:tables} = useQuery('admin/tables') 
  const filters = [
    { name: 'Table', key: 'table_id', list: tables?.data?.map(code => ({ name: code?.name, value: code?.id })) },
    { name: 'Status', key: 'status', list: ['Active', 'Inactive'].map(item => ({ name: item, value: item })) },
  ]
  return (
    <div className={styles.body}>
      <Header
        name={'Reserve Tables'} export_name={'table-bookings'}
        text={<><BiPlus /> Add Reservation</>}
        filterComponent={<FilterByList filters={filters} />}
        buttonOnclick={() => dispatch(openModal({ title: 'Reserve Table', content: <ReserveTable /> }))}
      >
        <Box width={'100%'} >
          <TopButtons
            btns={[
              { text: `Pending `, onClick: () => setSTAT('Active'), key: 'table' },
              { text: `Completed `, onClick: () => setSTAT('Completed'), key: 'venue' },
            ]}
          /></Box>
      </Header>
      <Table
        column={currentTable.column}
        // data={tableData} 
        // onSuccess={}
        appendQuery
        url={`/shop/secure/bookings?type=${currentTable.key}&status=${stat}`}
        actions={(data, i) => (
          <div>
            {/* <Button bgcolor='inherit ' w='fit-content' onClick={()=>navigate(`/accounting/invoice/create?booking_id=${data?.id}`)}>  {icons.plus} Create Invoice </Button>
            <Button bgcolor='inherit ' w='fit-content' onClick={()=>navigate(`/accounting/invoice/view-details?code=`)}>  {icons.viewDetail} View Invoice </Button> */}
            <Button bgcolor='inherit ' color='#EB2F2F' w='fit-content' onClick={() => dispatch(openModal(
              {
                title: 'Cancel reservation',
                content: <DeleteModal message={'Do you want to cancel this booking?'} method='patch' url={`/shop/secure/bookings/${currentTable.key}/${data?.id}/cancel`}  payload={{status:'Canceled'}} successMessage='Booking canceled' btnText='Proceed' />
              }))}>  {icons.delete} Cancel </Button>
          </div>
        )}
      />
    </div>
  );
};

export default PosReserveTable;
