import React, { useMemo, useState } from "react";
import { assets } from "Assets";
import { Header } from "Partial/Header";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { openModal } from "store/slices/modalSlice";
import { icons } from "Assets/icons";
import { Button, DeleteModal, EmptyState, Table, Text } from "Components";
import { BiPlus } from "react-icons/bi";
// import { useQuery } from 'react-query';
import dayjs from "dayjs";
import Status from "Components/Status";

import { path } from "./getPath";
import BookSnookerModal from "./components/BookSnookerModal";
import { ArrowBack, Check } from "@mui/icons-material";
import MakePayment from "Pages/Accounting/Invoice/MakePayment";
import { apiRequest } from "Services";
import FilterByList from "Components/Button/FilterByList";
import { useQuery } from "react-query";

const BookSnooker = () => {
  const [modal] = useState({ title: "Snooker", content: <BookSnookerModal /> });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // console.log({path})
  const isInWaiter = useMemo(() => {
    return pathname?.includes("waiter");
  }, [pathname]);
  const columns = [
    {
      name: "Requested on",
      key: "dateTime",
      render: (data) => dayjs(data?.requestedOn).format("MMM DD YYYY hh:mm A"),
    },
    {
      name: "Booking Number",
      key: "bookingId",
      // render: (data) => dayjs(data?.requestedOn).format("MMM DD YYYY hh:mm A"),
    },
    {
      name: "Customer Name",
      key: "customer.name",
      render: (data) => data?.customer?.name,
    },
    {
      name: "Service name",
      key: "service.name",
      render: (data) => data?.service?.name,
    },

    {
      key: "status",
      name: "Status",
      render: (data) => <Status data={data}>{data?.status}</Status>,
    },
  ];

  // const { data: service } = useQuery('/admin/service-price-lists?category=Snooker')
  const filters = [
    // { name: 'Snooker', key: 'name', list: service?.data?.map(code => ({ name: code?.name, value: code?.name })) },
    {
      name: "Status",
      key: "status",
      list: ["Paid", "Canceled", "Pending"].map((item) => ({
        name: item,
        value: item,
      })),
    },
  ];
  return (
    <>
      <Header
        name={
          <>
            {isInWaiter && <ArrowBack onClick={() => navigate("/waiter")} />}
            Snooker
          </>
        }
        text={
          <>
            {" "}
            <BiPlus /> Book snooker
          </>
        }
        buttonOnclick={() => dispatch(openModal(modal))}
        filterComponent={<FilterByList filters={filters} />}
      />
      {
        <Table
          column={columns}
          appendQuery
          // data={funding?.data?.data}
          url={`/shop/secure/bookings?type=snooker`}
          // url='/pos/customers/funding'
          // action={false}
          actions={(data) => (
            <div>
              {!data?.invoice?.unique_id && (
                <Button
                  bgcolor="inherit "
                  w="fit-content"
                  onClick={() =>
                    navigate(
                      `/${path()}/invoice/create?booking_id=${
                        data?.bookingId
                      }&category=Snooker`
                    )
                  }
                >
                  <Text color="black"> {icons.plus} Create Invoice </Text>
                </Button>
              )}

              {data?.invoice?.unique_id && (
                <Button
                  bgcolor="inherit "
                  w="fit-content"
                  onClick={async () => {
                    // console.log(data)
                    try {
                      const { data: deets } = await apiRequest({
                        url: `/shop/secure/invoices/${data?.invoice?.unique_id}`,
                      });
                      dispatch(
                        openModal({
                          title: "Confirm Payment",
                          content: (
                            <MakePayment
                              data={deets?.data}
                              customer={deets?.data?.customer}
                            />
                          ),
                        })
                      );
                    } catch (error) {}
                  }}
                >
                  <Text color="black">
                    {" "}
                    <Check /> Mark as paid{" "}
                  </Text>
                </Button>
              )}
              {data?.invoice?.unique_id && (
                <Button
                  bgcolor="inherit "
                  w="fit-content"
                  onClick={() =>
                    navigate(`/snooker-receipt/${data?.invoice?.unique_id}`)
                  }
                >
                  <Text color="black"> {icons.plus} Print Prebill </Text>
                </Button>
              )}
              {data?.invoice?.unique_id && (
                <Button
                  bgcolor="inherit "
                  w="fit-content"
                  onClick={() =>
                    navigate(
                      `/${path()}/invoice/view-details?invoice_id=${
                        data?.invoice?.unique_id
                      }`
                    )
                  }
                >
                  <Text color="black">{icons.viewDetail} View Invoice </Text>
                </Button>
              )}
              <Button
                bgcolor="inherit "
                shadow={false}
                color="#EB2F2F"
                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Cancel",
                      content: (
                        <DeleteModal
                          method="patch"
                          url={`/shop/secure/bookings/${"snooker"}/${
                            data?.id
                          }/cancel`}
                          successMessage="Booking canceled"
                          payload={{ status: "Canceled" }}
                          btnText="Proceed"
                          message={"Do you want to cancel this booking?"}
                        />
                      ),
                    })
                  )
                }
              >
                {" "}
                {icons.delete} Cancel
              </Button>
            </div>
          )}
          emptyState={
            <EmptyState
              icon={assets.icons.funding}
              text={"This is currently empty"}
            />
          }
        />
      }
    </>
  );
};

export default BookSnooker;
