import { Form, Input, Table } from "Components";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import styles from "../Inventory.module.scss";
import { apiRequest } from "Services";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useBranches } from "hooks";
import { closeModal } from "store/slices/modalSlice";
import { useDispatch } from "react-redux";
import { BiPlus } from "react-icons/bi";
import { Header } from "Partial/Header";
import { Close } from "@mui/icons-material";
import { useNavigate } from "react-router";
import FilterByList from "Components/Button/FilterByList";

const STORAGE_KEY = "inventoryTransferEntries";
const FORM_VALUES_KEY = "inventoryTransferFormValues";

const Drop = ({
  placeholder,
  options,
  onchange,
  idKey = "id",
  labelKey = "name",
  name,
  defaultValue,
}) => {
  return (
    <select
      className=""
      name={name}
      onChange={onchange}
      value={defaultValue || ""}
    >
      <option value="">{placeholder}</option>
      {options?.map((x) => (
        <option key={x[idKey]} value={x[idKey]}>
          {x[labelKey]}
        </option>
      ))}
    </select>
  );
};

const RequestInvTransfer2 = ({ state = 0 }) => {
  const dispatch = useDispatch();
  const { branches } = useBranches();
  const { data: itemsQuantity } = useQuery("/reports/inventory-items");

  const [formValues, setFormValues] = useState({});
  const { data: items } = useQuery(`/admin/inventory-items`);
  const [isLoading, setLoading] = useState(false);

  const [entries, setEntries] = useState([]);

  const { data: all_source } = useQuery(`/admin/inventory-source`);

  const { data: source_from } = useQuery(
    `/admin/branch/${formValues?.branch_id_from}/inventory-source`,
    { enabled: Boolean(formValues?.branch_id_from) }
  );
  const { data: source_to } = useQuery(
    `/admin/branch/${formValues?.branch_id_to}/inventory-source`,
    { enabled: Boolean(formValues?.branch_id_to) }
  );

  const getSourceByBranch = useCallback(
    (branch_id) => {
      if (branch_id) {
        const list = all_source?.data?.filter(
          (source) => source?.branch?.id === parseInt(branch_id)
        );
        return list;
      }
      return [];
    },
    [all_source?.data]
  );

  const handleChange = (e) => {
    setFormValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const navigate = useNavigate();

  // Load entries from localStorage on initial render
  useEffect(() => {
    try {
      const storedEntries = localStorage.getItem(STORAGE_KEY);
      const storedFormValues = localStorage.getItem(FORM_VALUES_KEY);

      if (storedEntries) {
        setEntries(JSON.parse(storedEntries));
      }

      if (storedFormValues) {
        setFormValues(JSON.parse(storedFormValues));
      }
    } catch (error) {
      console.error("Error loading from localStorage:", error);
      // Handle error appropriately
    }
  }, []);

  // Save entries to localStorage whenever they change
  useEffect(() => {
    if (entries.length > 0) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(entries));
    } else {
      localStorage.removeItem(STORAGE_KEY);
    }
  }, [entries]);

  useEffect(() => {
    if (Object.keys(formValues).length > 0) {
      localStorage.setItem(FORM_VALUES_KEY, JSON.stringify(formValues));
    } else {
      localStorage.removeItem(FORM_VALUES_KEY);
    }
  }, [formValues]);

  const addEntry = () => {
    const id = Date.now();
    setEntries((prev) => [
      ...prev,
      {
        id,
        inventory_item_id: null,
        from_branch: "",
        from_inventory_source: "",
        amount: "",
        sending_unit: "",
        to_branch: "",
        to_inventory_source: "",
        receiving_qty: "",
        receiving_unit: "",
      },
    ]);
  };

  const deleteEntry = (id) =>
    setEntries((prev) => prev.filter((x) => x.id !== id));

  const getUnit = useCallback(
    (id) => {
      return items?.data?.find((item) => item?.id?.toString() === id)?.boh_unit
        ?.short_name;
    },
    [items?.data]
  );

  const getStatus = useCallback(
    (item_id, source_id, amount) => {
      if (source_id && item_id) {
        const item = items?.data?.find(
          (item) => item?.id?.toString() === item_id
        );
        const source = all_source?.data?.find(
          (source) => source?.id?.toString() === source_id
        );
        const relation = itemsQuantity?.data?.items?.find(
          (itemsQuantity) =>
            itemsQuantity?.display_name === item?.name &&
            itemsQuantity?.house_name === source?.name
        );
        if (relation) {
          const qty = relation?.qty;
          if (qty > amount) {
            return `Enough (${qty})`;
          } else {
            return `Not enough (${qty})`;
          }
        }
        return "Not relation";
      }
      return "Item and from source required";
    },
    [itemsQuantity?.data]
  );

  const getReceivingQty = useCallback(
    (item_id, from_source, to_source, amount) => {
      if (item_id && from_source && to_source && amount) {
        const item = items?.data?.find(
          (item) => item?.id?.toString() === item_id
        );
        const from = all_source?.data?.find(
          (source) => source?.id?.toString() === from_source
        );
        const to = all_source?.data?.find(
          (source) => source?.id?.toString() === to_source
        );
        if (from?.type === to?.type) return amount;
        if (from?.type === "boh" && to?.type === "moh")
          return amount * item?.boh_to_moh_conversion_rate?.moh;
        if (from?.type === "moh" && to?.type === "boh")
          return amount / item?.boh_to_moh_conversion_rate?.moh;
      }
      return 0;
    },
    [all_source?.data]
  );

  const editEntry = (i, e) => {
    setEntries((prev) => {
      return prev.map((entry, index) =>
        index === i ? { ...entry, [e.target.name]: e.target.value } : entry
      );
    });
  };

  const columns = useMemo(
    () => [
      {
        name: "Item",
        key: "item",
        render: (data, i) => {
          return (
            <Drop
              onchange={(e) => {
                editEntry(i, e);
              }}
              name={"inventory_item_id"}
              placeholder={"Select inventory item"}
              defaultValue={entries[i]?.inventory_item_id}
              options={items?.data}
            />
          );
        },
      },
      {
        name: "From branch",
        key: "from_branch",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"from_branch"}
            placeholder={"Select branch"}
            defaultValue={entries[i]?.from_branch}
            labelKey="name"
            options={branches}
          />
        ),
      },
      {
        name: "From source",
        key: "from_inventory_source",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"from_inventory_source"}
            defaultValue={entries[i]?.from_inventory_source}
            placeholder={"Select source"}
            labelKey="name"
            idKey="id"
            options={getSourceByBranch(data?.from_branch)}
          />
        ),
      },
      {
        name: "Sending Qty",
        key: "amount",
        render: (data, i) => (
          <input
            className=""
            type="number"
            name={"amount"}
            value={entries[i]?.amount}
            onChange={(e) => editEntry(i, e)}
          />
        ),
      },

      {
        name: "To branch",
        key: "to_branch",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"to_branch"}
            placeholder={"Select branch"}
            defaultValue={entries[i]?.to_branch}
            labelKey="name"
            options={branches}
          />
        ),
      },
      {
        name: "To source",
        key: "to_inventory_source",
        render: (data, i) => (
          <Drop
            onchange={(e) => {
              editEntry(i, e);
            }}
            name={"to_inventory_source"}
            placeholder={"Select source"}
            defaultValue={entries[i]?.to_inventory_source}
            labelKey="name"
            idKey="id"
            options={getSourceByBranch(data?.to_branch)}
          />
        ),
      },
      {
        name: "Receieving Qty",
        key: "receiving_qty",
        render: (data, i) => (
          <input
            className=""
            type="number"
            value={getReceivingQty(
              data?.inventory_item_id,
              data?.from_inventory_source,
              data?.to_inventory_source,
              data?.amount
            )}
            name={"receiving_qty"}
            onChange={(e) => editEntry(i, e)}
          />
        ),
      },
      {
        name: "Unit",
        key: "unit",
        render: (data, i) => (
          <input
            className="outline-none"
            value={getUnit(data?.inventory_item_id)}
            key={data?.inventory_item_id}
            name={"sending_unit"}
          />
        ),
      },
      {
        name: "Status",
        key: "status",
        render: (data, i) => (
          <input
            className="outline-none"
            value={getStatus(
              data?.inventory_item_id,
              data?.from_inventory_source,
              data?.amount
            )}
            disabled
            key={data?.inventory_item_id}
            name={"status"}
          />
        ),
      },
      {
        name: "Action",
        key: "action",
        render: (data, i) => (
          <Close className="pointer" onClick={() => deleteEntry(data?.id)} />
        ),
      },
    ],
    [items, branches, source_from, source_to, getUnit, entries]
  );

  const submit = async () => {
    setLoading(true);
    try {
      await apiRequest({
        method: "post",
        url: "/operations/inventory-transfer",
        payload: { ...formValues, inventories: entries },
        hasAuth: true,
      });
      toast.success("Transfer successful");

      // Clear localStorage after successful submit
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(FORM_VALUES_KEY);

      navigate("/operations/inventory-transfer");
      dispatch(closeModal());
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit inventory transfer");
    } finally {
      setLoading(false);
    }
  };

  const filters = [
    {
      name: "Status",
      key: "status",
      list: ["approved", "pending"].map((btn) => ({ name: btn, value: btn })),
    },
  ];

  return (
    <div className={`p-3 ${styles.reqInvTran}`}>
      <Header
        name="Initiate new Inventory Transfer"
        text={<>Submit</>}
        buttonOnclick={submit}
        filterComponent={<FilterByList filters={filters} />}
      />
      <Form className={"mb-5 !w-[50%] pl-3"}>
        <Input
          label="Select Date"
          type="date"
          placeholder="Select date"
          name={"date"}
          className={"!w-50"}
          onChange={handleChange}
          defaultValue={formValues.date || ""}
        />
        <Input
          label="Reason"
          type="textarea"
          placeholder="Reason"
          name={"reason"}
          className={"!w-50"}
          onChange={handleChange}
          defaultValue={formValues.reason || ""}
        />
      </Form>

      <div className={""}>
        <div className={""}>
          <Table
            noPagination
            defaultRowPerpage={100}
            column={columns}
            data={entries}
            action={false}
          />
          <div className="flex w-full justify-center my-5 ">
            <div
              className="rounded-[100px] font-bold cursor-pointer flex gap-4 py-2 px-5 items-center bg-[#F7F7F7]"
              onClick={addEntry}
            >
              <BiPlus fontWeight={600} /> Add item
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestInvTransfer2;
