import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Box } from "@mui/material";

const MultiStepProgressBar = ({ vertical = false, current = 0, points = [], onChange = () => null, next = () => null }) => {
    const Next = ({ active, first, content, onClick = () => null, done }) => (
        <Box onClick={onClick} className={`${styles.next} ${vertical ? styles.vertical : '  '}`}>
            {!first && <Box className={`${vertical ? styles.verticalLine : styles.line}`} />}
            <Box className={`${styles.step} ${active ? styles.active : ' '}  ${done ? styles.done : ' '}`} children={content} />
        </Box>
    )
    const [point, setPoint] = useState(current)
    useEffect(() => {
        onChange(point)
    }, [point, onChange])
    useEffect(() => {
        setPoint(current)
    }, [current])
    return (
        <Box component={'div'} className={`${styles.container} ${vertical ? styles.vertical : '  '}`}>
            {points.map((x, i) => (<Next first={i === 0} active={point === i} done={point > i} content={i + 1} onClick={() => setPoint(i)} />))}
        </Box>
    );
};

export default MultiStepProgressBar;
