import { Box } from '@mui/material'
import { BtnStack, Button, CheckInput, FlexText, Form, Input } from 'Components'
import React, { useEffect, useState } from 'react'
import styles from '../Funding.module.scss'
import { changeTitle, openModal } from 'store/slices/modalSlice';
import SuccessfulModal from './Successful'
import { SelectCustomer } from './AssignCustomer'
import { useSelector, useDispatch } from 'react-redux'
import { apiRequest } from 'Services'
import ReviewConfirm from 'Pages/Pos/Bookings/components/ReviewConfirm'
import { formatPrice } from 'utils'

const RequestInvTransfer = ({ state = 0 }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(changeTitle('Fund Wallet'))
  }, [])
  const [current, setCurrent] = useState(state)
  const customer = useSelector(state => state?.customer?.data)
  const [formValues, setFormValues] = useState({
    "customer_id": customer?.id,
    "transaction_type": "credit",
    "payment_method": "",
    "amount": 20000.00
  })
  const change = (e) => {
    setFormValues(prev => ({ ...prev, [e?.target?.name]: e?.target?.value }))
  }
  useEffect(() => {
    setFormValues(prev => ({ ...prev, "customer_id": customer?.id }))
  }, [customer])
  const submit = async () => {
    try {
      await apiRequest({
        url:formValues?.transaction_type==='credit'?  '/pos/customers/funding':'',
        method: 'post',
        payload: formValues, hasAuth: true
      })
      setCurrent(3)
      dispatch(openModal({ title: 'Funding Successful', content: <SuccessfulModal /> }))

    } catch (error) {
      console.log(error)
    }
  }

  const One = () => (
    <Box width={'100%'} display={'flex'} flexDirection={'column'} gap={'24px'} className='relative'>
      <div className='flex flex-col gap-2'>
        <p>Select Payment Method</p>
        <CheckInput label='Cash' type='checkbox' value='Cash' name='payment_method' checked={formValues.payment_method === 'Cash'} onChange={change} />
        <CheckInput label='Bank Transfer' type='checkbox' name='payment_method' value="Bank Transfer" checked={formValues.payment_method === 'Bank Transfer'} onChange={change} />
      </div>
      <Button onClick={() => setCurrent(1)}>Proceed</Button>
    </Box>
  )

  const Details = () => (
    <>

      {/* <FlexText leftText='Date' rightText={customer?.id} /> */}
      <FlexText leftText='Customer ID' rightText={customer?.id} />
      <FlexText leftText='Customer Name' rightText={customer?.full_name} />
      <FlexText leftText='Phone Number' rightText={customer?.phone} />
      <FlexText leftText='Email Address' rightText={customer?.email} />
      <FlexText leftText='Membership' rightText={customer?.membership?.display_name} />
      <FlexText leftText='Payment Method' rightText={formValues.payment_method} />


      <FlexText leftText="Balance" rightText={"" + (customer?.balance ? formatPrice(customer?.balance) : '₦0')} />
      <FlexText leftText="Available purchase limits" rightText={formatPrice(customer?.available_purchase_limits)} /></>
  )


  return (
    <div className={styles.Funding}>
      <Form>
        {current === 0 && <SelectCustomer >
          <One />
        </SelectCustomer>}
        {current === 1 && <>
          <Details />
          <Input placeholder='0' label='Enter Amount' name='amount' onChange={change} />
          <BtnStack leftClick={() => setCurrent(0)} rightClick={() => setCurrent(2)} />
        </>}

        {current === 2 && <ReviewConfirm
          prev={() => setCurrent(1)}
          next={() => submit()} >
          <Details />
          <FlexText leftText='Total' rightText={formatPrice(formValues.amount)} />
        </ReviewConfirm>}

        {current === 3 && <SuccessfulModal />}
      </Form>
    </div>
  )
}

export default RequestInvTransfer;