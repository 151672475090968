import { Button, FlexText } from 'Components'
import dayjs from 'dayjs'
import React from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { openModal } from 'store/slices/modalSlice'
import MarkAsPaidForm from './MarkAsPaidForm'

const MarkAsPaidDeets = ({ data }) => {

    const { data: suppliers } = useQuery('admin/suppliers')
    const dispatch = useDispatch()

    return (
        <div className='flex flex-col gap-3 w-100'>
            <FlexText leftText='Date' rightText={dayjs(data?.created_at).format('DD MMM, YYYY. hh:mm')} />
            <FlexText leftText='Purchase Order Id' rightText={data?.purchase_order_no} />
            <FlexText leftText='Vendor name' rightText={suppliers?.data?.find(x => x.id === data?.supplier_id)?.company_name} />
            <FlexText leftText='Paid Through' />
            <FlexText leftText='Status' rightText={data?.order_status} rightColor='#2463FF' />

            <Button onClick={
                () => dispatch(openModal({ title: 'Mark As Paid', content: <MarkAsPaidForm  data={data} /> }))
            } bgcolor='#EFEFEF' radius='100px' color='#FF0030'>Mark As Paid</Button>
        </div>
    )
}

export default MarkAsPaidDeets