// import React from 'react'
import { useQuery } from 'react-query'

const usePayMethods = (type) => {
    const { data: methods } = useQuery('/collections/payment-methods')

    return {
        methods: methods?.data
    }
}

export { usePayMethods }