import React, { useState } from "react";
import { EmptyState, Button, Table } from "../../../Components";
import { assets } from "../../../Assets";
import { Header } from "../../../Partial/Header";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/slices/modalSlice";
import { icons } from "../../../Assets/icons";
import styles from "./MyCustomers.module.scss";
import ViewDetails from "./components/ViewDatails";
// import Edit from "./components/Edit";
// import FundWallet from "./components/FundWallet";
// import ViewHistory from "./components/ViewHistory";
// import SetDiscount from "./components/SetDiscount";
// // import SetDiscount from "./components/SetDiscount";
// import AddCustomer from "./AddCustomer";
// import SetOverdraft from "./components/SetOverDraft";

const ReservedAccount = () => {

  const [state] = useState(false);
  const dispatch = useDispatch();
  // Firstname
  // Lastname
  // Email
  // Phone
  // Account N0
  // Bank Name
  // Bank Code
  // Action(Reserve Account)

  const columns = [
    { name: "Firstname", key: "name" , render:(data)=>data?.customers?.full_name},
    // { name: "Lastname", key: "name" },
    { name: " Email", key: "phone" ,render:(data)=>data?.customers?.phone},
    { name: "Phone", key: "email",render:(data)=>data?.customers?.email},
    { name: "Account number", key: "account_no", },
    { name: " Bank Name", key: "bank_name" },
    { name: " Bank Code", key: "bank_code" },
    // { name: "Balance", key: "balance", render: (data) => data?.balance === null ? "₦" + 0 : "₦" + data?.balance?.toLocaleString() }
  ];
  return (
    <>
      <Header
      export_name={'reserved-accounts'}
        name={"Reserved Accounts"}
        // text={"Add Customer"}
        // buttonOnclick={() =>
        //   dispatch(
        //     openModal({ title: "Create Customer", content: <AddCustomer /> })
        //   )
        // }
      />
      {state ? (
        <EmptyState
          icon={assets.icons.rolesPermissions}
          text={"There are no roles and permission created yet"}
        />
      ) : (
        <Table
          column={columns}
          // data={roles}
          url="operations/customers/reserved-accounts"
          action={false}
          actions={(data) => (
            <div className={styles.btns}>
              <Button

                onClick={() =>
                  dispatch(
                    openModal({
                      title: "View Details",
                      content: <ViewDetails user={data} />,
                    })
                  )
                }
                bgcolor="inherit"
                shadow={false}
                color="black"
              >
                {" "}
                {icons.viewDetail}Reserve Account
              </Button>

              {/* <Button
                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Edit Customer",
                      content: <AddCustomer btnText="save" user={data} />,
                    })
                  )
                }
                bgcolor="inherit"
                shadow={false}
                color="black"
              >
                {icons.edit}Edit
              </Button>

              <Button
                onClick={() =>
                  dispatch(
                    openModal({ title: "Fund Wallet", content: <FundWallet user={data} /> })
                  )
                }
                bgcolor="inherit"
                shadow={false}
                color="black"
              >
                {icons.fundWallet}Fund Wallet
              </Button>

              <Button
                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Customer History",
                      content: <ViewHistory user={data} />,
                    })
                  )
                }
                bgcolor="inherit"
                shadow={false}
                color="black"
              >
                {icons.viewHistory}View history
              </Button>

              <Button

                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Set overdraft limit",
                      content: <SetOverdraft btnText="Set overdraft" user={data} />,
                    })
                  )
                }
                bgcolor="inherit"
                shadow={false}
                color="black">
                {icons.overdraft}Set overdraft
              </Button>

              <Button
                onClick={() =>
                  dispatch(
                    openModal({
                      title: "Set Discount",
                      content: <SetDiscount btnText="Set discount" user={data} />,
                    })
                  )
                }
                bgcolor="inherit"
                shadow={false}
                color="black"
              >
                {icons.setDiscount}Set discount
              </Button>

              <Button bgcolor="inherit" shadow={false} color="#EB2F2F"
                onClick={() =>
                  dispatch(
                    openModal({
                      title: data?.status === 'Suspended' ? 'Unblock' : 'Block'  + " Customer",
                      content: 
                      <DeleteModal
                        btnText={data?.status === 'Suspended' ? 'Unblock' : 'Block' }
                        message={
                        `Are you sure you want to ${data?.status === 'Suspended' ? 'unblock' : 'block' } this customer`
                        }
                        // payload={{ status: "Completed" }}
                        // onDelete={}
                        method="patch"
                        url={`/operations/customers/${data?.id}/suspend`}
                        successMessage={'Successful'}
                      />
                    })
                  )
                }
              >
                {icons.disable} { data?.status === 'Suspended' ? 'Unblock' : 'Block' }  
              </Button> */}
            </div>
          )}
        />
      )}
    </>
  );
};

export default ReservedAccount;
