import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    selectedFilters: {},
    queryParams: ''
}

export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setSelectedFilters: (state, { payload }) => {
            // console.log(payload)
            state.selectedFilters = payload
            state.queryParams = new URLSearchParams(state.selectedFilters).toString();
        },
        clearFilter: (state, { payload }) => {
            const newFilter = (Object.entries(state.selectedFilters).filter(filter => filter[0] !== payload))
            console.log(Object.fromEntries(newFilter))
            state.selectedFilters = Object.fromEntries(newFilter)
            state.queryParams = new URLSearchParams(state.selectedFilters).toString();
            // console.log(state.selectedFilters[payload], payload)
        },
        clearFilters: (state) => {
            state.selectedFilters = {}
            state.queryParams = ''
        }
    },
})


export const { setSelectedFilters, clearFilter, clearFilters } = filterSlice.actions

export default filterSlice.reducer