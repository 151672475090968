import { Close } from "@mui/icons-material";
import { assets } from "Assets";
import { Button, EmptyState, Form, Input, Table, Text } from "Components";
import { apiRequest } from "Services";
import dayjs from "dayjs";

import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { selectOpts } from "utils";

const GlAccountTransactions = () => {
  // Date
  // SBU
  // Account Type
  // Account
  // Description
  // Debit
  // Credit
  const columns = [
    {
      name: "Date",
      key: "created_at",
      render: (data) => dayjs(data?.created_at).format("MMM DD, YYYY. hh:mm A"),
    },
    { name: "SBU", key: "account_code" },
    { name: "Account Type", key: "account_type" },
    { name: "Account", key: "account_name" },
    { name: "Description", key: "transaction_type" },
    { name: "Credit", key: "credit" },
    { name: "Debit", key: "debit" },
  ];
  const [params, setParams] = useState({ sort: "desc,created_at" });
  const [query, setQuery] = useState("");
  const [dates, setDates] = useState([]);

  const { data: account_code } = useQuery(`/admin/accounting/accounts`);
  const { data: sbu } = useQuery("/admin/accounting/sbu-codes");
  const { data: type } = useQuery("/admin/accounting/account-types");

  const code_list = useCallback(
    (account_type_id, sbu_code_id) => {
      if (account_type_id && sbu_code_id) {
        let match = account_code?.data?.filter(
          (code) =>
            code?.account_type_id === account_type_id &&
            code?.sbu_code_id === sbu_code_id
        );
        return selectOpts(match, "name", "id");
      }
      return [];
    },
    [account_code]
  );

  const handleChange = (name, value) =>
    setParams((prev) => ({ ...prev, [name]: value }));
  useEffect(() => {
    handleChange("date", dayjs(Date.now()).format("YYYY-MM-DD"));
  }, []);
  const generate = async () => {
    const str = new URLSearchParams(params).toString();
    setQuery(`?${str}`);

    const { data: res } = await apiRequest({
      url: `/reports/account-transactions/download/csv?${str}`,
      hasAuth: true,
      method: "get",
    });
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([res]));
    link.href = url;
    const fileName = `Account Transactions Report ${dayjs(new Date()).format(
      "DD MMM YY"
    )}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  useEffect(() => {
    const str = new URLSearchParams(params).toString();
    setQuery(`?${str}`);
  }, []);
  const clear = () => {
    setQuery("");
  };
  const { data: report } = useQuery(`reports/account-transactions${query}`);
  // const { data: sources } = useQuery('/admin/inventory-source')

  //   From
  //   To
  //   SBU
  //   Account Type
  //   Account

  const handleDates = (e) => {
    const index = e.target.name;
    const date = dayjs(e.target.value).format("YYYY-MM-DD");
    setDates((prev) => {
      let values = prev;
      values[index] = date;
      return values;
    });
    handleChange("created_at[btw]", JSON.stringify(dates));
  };

  return (
    <div className=" flex flex-col gap-4">
      <div className="px-[40px]">
        <Close onClick={clear} />
        <Form
          className="border flex justify-around items-center p-4 my-3 rounded-[12px]"
          direction={"row"}
        >
          <Input
            placeholder="From"
            label="From (date)"
            name="0"
            type="date-time"
            onChange={handleDates}
          />
          <Input
            placeholder="To"
            label="To (date)"
            name="1"
            type="date-time"
            onChange={handleDates}
          />
          <Input
            placeholder="Select SBU"
            label="Select SBU"
            name="sbu_code"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            type="select"
            option={selectOpts(sbu?.data?.filter((code) => code?.status))}
          />
          <Input
            placeholder="Account Type"
            label="Select account type"
            name="account_type"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            type="select"
            option={selectOpts(type?.data)}
          />
          <Input
            placeholder="Account code"
            label="Select account code"
            name="account_id"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            type="select"
            key={params?.account_type + params?.sbu_code}
            option={code_list(params?.account_type, params?.sbu_code)}
          />
          {/* <Input placeholder="Select branch" label='Select branch' name="inventory_source_branch"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            type='select' option={selectOpts(branches)} /> */}
          {/* <Input placeholder="Date" label="File Type" type="select" option={[{ name: 'CSV', value: 'csv' }, { name: 'PDF', value: 'pdf' }]} name/> */}
          {/* <Input placeholder="Select House Type" label="House Type" type="select" onChange={(e) => handleChange(e.target.name, e.target.value)}
            option={[{ label: 'MOH', value: 'moh' }, { label: 'BOH', value: 'boh' }]} name={'with_inventory_source_type'} /> */}
          {/* <Input placeholder="Select Inventory Source" label="Inventory Source" type='select' name={'inventory_source'}
            option={selectOpts(sources?.data)} onChange={(e) => handleChange(e.target.name, e.target.value)} /> */}
          <Button className={"h-[100%] !mt-3"} onClick={generate}>
            Generate Report
          </Button>
        </Form>
        <div className="">
          <Text weight="700" size="26px">
            GL Account Transactions
          </Text>
        </div>
      </div>
      <Table
        column={columns}
        data={report?.data?.transactions}
        emptyState={
          <EmptyState
            icon={assets.icons.staffMagt}
            text={"There are no report  yet"}
          />
        }
        // url='/operations/reports/inventory-items'
        action={false}
      />
    </div>
  );
};

export default GlAccountTransactions;
