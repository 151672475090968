import { Close } from "@mui/icons-material";
import { assets } from "Assets";
import { Button, EmptyState, Form, Input, Table, Text } from "Components";
import { apiRequest } from "Services";
import dayjs from "dayjs";
import { useBranches } from "hooks";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { selectOpts } from "utils";
import { formatDate } from "utils/formatPrice";

const ProfitLoss = () => {
  // Date
  // SBU
  // Account Type
  // Account
  // Description
  // Debit
  // Credit
  // const columns = [
  //   { name: 'Date', key: 'created_at', render: (data) => dayjs(data?.created_at).format('MMM DD, YYYY. hh:mm A') },
  //   { name: 'SBU', key: 'account_code', },
  //   { name: 'Account Type', key: 'account_type', },
  //   { name: 'Account', key: 'account_name', },
  //   { name: 'Description', key: 'transaction_type' },
  //   { name: 'Credit', key: 'credit' },
  //   { name: 'Debit', key: 'debit' },

  // ]
  const [params, setParams] = useState(
    // {sort:'desc,created_at'}
    { tax: 4 }
  );
  const [query, setQuery] = useState("");
  // const [dates, setDates] = useState([])
  //
  // const { data: account_code } = useQuery(`/admin/accounting/accounts`)
  const { data: sbu } = useQuery("/admin/accounting/sbu-codes");
  // const { data: type } = useQuery('/admin/accounting/account-types')
  // const [dates, setDates] = useState('')

  const handleChange = (name, value) =>
    setParams((prev) => ({ ...prev, [name]: value }));
  // useEffect(() => {
  //   handleChange('date', dayjs(Date.now()).format('YYYY-MM-DD'))
  // }, [])
  const generate = async () => {
    const str = new URLSearchParams(params).toString();
    const add_query = `?${str}`;
    setQuery(add_query);

    const { data: res } = await apiRequest({
      url: `/reports/profit-loss-report/csv${add_query}`,
      hasAuth: true,
      method: "get",
    });
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([res]));
    link.href = url;
    const fileName = `Profit and Loss Report ${dayjs(new Date()).format(
      "DD MMM YY"
    )}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    console.log(params);
    console.log(add_query);
  };
  useEffect(() => {
    const str = new URLSearchParams(params).toString();
    setQuery(`?${str}`);
  }, []);
  const clear = () => {
    setQuery("");
  };
  // const { data: report } = useQuery(`/reports/profit-loss-report${query}`)

  // const handleDates = (e) => {
  //   const index = e.target.name
  //   const date = dayjs(e.target.value).format('YYYY-MM-DD')
  //   setDates(prev => {
  //     let values = prev;
  //     values[index] = date;
  //     return values
  //   })
  //   handleChange('created_at[btw]', JSON.stringify(dates))
  // }

  return (
    <div className=" flex flex-col gap-4">
      <div className="pl-4 my-2">
        <Text weight="700" size="26px" className="my-3">
          Generate Profit & Loss Report{" "}
        </Text>
      </div>
      <div className="px-[40px]">
        <Close onClick={clear} />
        <Form
          className="border flex justify-around items-center p-4 my-3 rounded-[12px]"
          direction={"row"}
          onSubmit={console.log}
        >
          <Input
            placeholder="From"
            label="From (date)"
            name="start_date"
            type="date"
            onChange={(e) =>
              handleChange(
                e.target.name,
                dayjs(e.target.value).format("YYYY-MM-DD")
              )
            }
          />
          <Input
            placeholder="To"
            label="To (date)"
            name="end_date"
            type="date"
            onChange={(e) =>
              handleChange(
                e.target.name,
                dayjs(e.target.value).format("YYYY-MM-DD")
              )
            }
          />

          {/* <Input placeholder="From" label='From (date)' name='sbustart_date' type='date' onChange={handleDates} />
          <Input placeholder="To" label='To (date)' name='end_date' type='date' onChange={handleDates} /> */}

          <Input
            placeholder="Select SBU"
            label="Select SBU"
            name="sbu_code"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            type="select"
            option={selectOpts(
              sbu?.data?.filter((code) => code?.status),
              "name",
              "id"
            )}
          />
          <Input
            placeholder="tax"
            label="Tax"
            name="tax"
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Button className={"h-[100%] !mt-3"} type="submit" onClick={generate}>
            Generate Report
          </Button>
        </Form>
      </div>
      {/* <Table
        column={columns}
        data={report?.data?.transactions}
        emptyState={<EmptyState icon={assets.icons.staffMagt} text={'There are no report  yet'} />}
        // url='/operations/reports/inventory-items'
        action={false}
      /> */}
    </div>
  );
};

export default ProfitLoss;
