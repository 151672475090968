import { Box } from '@mui/material'
import React, { useState } from 'react'
import { Button } from '.'
const TopButtons = ({
    btns = [
        { text: 'Text', onClick: () => null, key: 'key' }
    ],
    containerStyles = {},
    defaultValue
    // active = 0
}) => {
    const [active, setActive] = useState(defaultValue || 0)
   
    const click = (text, value, i, onClick) => {
        setActive(i)
        onClick(text, value, i)
    }
    return (
        <Box ml={'24px'} display={'flex'} gap={'10px'} height={'40px'} sx={{ ...containerStyles }}>
            
            {
                btns
                    .map(({ text, onClick, key, value }, i) => {
                        const id = Math.random()
                        return <Button 
                            key={id}
                            onClick={() => click(text, value, i, onClick)}
                            shadow={false} w='fit-content'
                            radius='100px'
                            bgcolor={active === i ? '#FF0030' : '#EFEFEF'}
                            color={active === i ? '#FFFFFF' : '#4F4F4F'} style={{whiteSpace : 'no-wrap'}}>
                            {text}
                        </Button>
                    })
            }
            {/* <Button shadow={false} w='fit-content' radius='100px' bgcolor='#EFEFEF' color='#4F4F4F'>
                Contact person
            </Button> */}
        </Box>
    )
}

export { TopButtons }