import Pusher from "pusher-js";
import React, { useEffect } from "react";
import Echo from "laravel-echo";
import axios from "axios";
import { useSelector } from "react-redux";

const BeHivePusher = ({
  type = "listen",
  event_prop = "",
  channel_prop = "",
  id = null,
}) => {
  const state = useSelector((state) => state);
  // console.log(state)

  const sendNoti = (data) => {
    Notification.requestPermission().then(function (permission) {
      if (permission === "granted") {
        if (permission === "granted") {
          const title = data?.title;
          const text = data?.message;
          new Notification(title, { body: text, tag: text });
        }
      } else {
      }
    });
  };
  useEffect(() => {
    const echo = new Echo({
      broadcaster: "pusher",
      key: "265285e871d18dfe1698",
      cluster: "eu",
      forceTLS: true,
      authEndpoint:
        "https://be-global-api.gurugeeksroyalty.net/api/broadcasting/auth",
      authorizer: (channel) => {
        return {
          authorize: (socketId, callback) => {
            axios
              .post(
                "https://be-global-api.gurugeeksroyalty.net/api/broadcasting/auth",
                {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${state?.user?.access_token}`,
                  },
                }
              )
              .then((response) => {
                callback(null, response.data);
              })
              .catch((error) => {
                callback(error);
              });
          },
        };
      },
    });

    const channels_and_events = [
      {
        channel: "orders",
        event: "order-created",
      },
      {
        channel: "orders-status",
        event: "order-status-change",
      },
      // {channel:'',}
      {
        channel: "bookings",
        event: "booking-created",
      },
    ];

    // const channel = echo.private(channel_);
    // channel.listen(event, function (data) {
    //     sendNoti(data)
    // });
    const channels = channels_and_events.map((item) => ({
      channelInstance: echo.private(item.channel),
      ...item,
    }));
    channels.forEach((item) => {
      item.channelInstance.listen(`.${item.event}`, function (data) {
        sendNoti(data);
      });
    });

    // if (channel_prop && event_prop) {
    //     const channel = echo.private(channel_prop);
    //     channel.listen(event_prop, function (data) {
    //         sendNoti(data)
    //     });
    // }

    return () => {
      channels.forEach((item) => {
        item.channelInstance.stopListening(`.${item.event}`, () => {
          console.log("Stopped listening to event : ", item.event);
        });
      });
    };
  }, []);
  return <span>{/* pusher */}</span>;
};

export default BeHivePusher;
