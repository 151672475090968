
import { FaWallet } from 'react-icons/fa'
import { assets } from '../../Assets'
import { operations_icons as oi } from 'Pages/Operations/operationIcons'
const base = '/accounting'

export const accounting_paths = [
  

    {
        path: `${base}#`.toLowerCase(), name: 'Disbursement', icon: assets.icons.receipt, children: [
            { path: base + '/expenses', name: 'Expenses'},
            { path: base + '/purchase-order', name: 'Purchase Order'},

        ]
    },
    { name: 'WorkList', path: `${base}/WorkList`.toLowerCase(), icon: oi.worklist },//---
    { path: base + '/manual-journal', name: 'Manual Journal', icon: assets.icons.dashboard },
    { path: base + '/invoice', name: 'Invoice', icon: assets.icons.dashboard },
    { path: base + '/Assets', name: 'Assets', icon: assets.icons.dashboard },
    { path: base + '/orders', name: 'Orders', icon: assets.icons.dashboard },
    { name: 'Funding', path: `${base}/funding`.toLowerCase(), icon: <FaWallet /> },//----
    { name: 'Aging Receivable', path: `${base}/AgingReceivable`.toLowerCase(), icon: oi.aging_receivable },//----
    {
        path: `${base}#`.toLowerCase(), name: 'Report', icon: assets.icons.receipt, children: [
            // { path: base + '/income-report', name: 'Income Report' },
            // { path: base + '/inventory-statement-report', name: 'Inventory statement report' },
            // { path: base + '/inventory-transaction-report', name: 'Inventory transaction report' },
            { path: base + '/gl-account-report', name: 'GL Account  Balance Report' },
            { path: base + '/Sales-margin-report', name: 'Sales margin report' },
            { path: base + '/gl-account-transactions', name: 'GL Account Transactions' },
            { path: base + '/cash-flow', name: 'Cash Flow Statement' },
            { path: base + '/balance-sheet', name: 'Balance Sheet' },
            { path: base + '/profit-and-loss', name: 'Profit & Loss Report' },

        ]
    }

]
