import dayjs from "dayjs"

export const formatPrice = (price, sign = '₦') => price ? `${sign}${price?.toLocaleString()}` : `${sign}0`
export const formatDate = ({ date, type = 'datetime' }) => {
 
   if (type === 'time') {
      return dayjs(date).format('hh:mm A')
   } else if (type === "date") {
      return dayjs(date).format('MMM DD, YYYY')
   } else if (type === "backenddate") {
      return dayjs(date).format('YYYY-MM-DD')
   } else if (type === "backendtime") {
      return dayjs(date).format('HH:mm')
   } else {
      return dayjs(date).format('MMM DD, YYYY hh:mm A')
   }
}
export const calSum = (arr = []) => {
   return arr?.length ? arr.reduce(function (a, b) {
      return a + b;
   }) : 0
}