import React from 'react'
import styles from './WorklList.module.scss'
import ApproveBtns from './ApproveBtns'
import { FlexText } from 'Components'

const ApproveExpense = () => {
    return (
        <div className={styles.container}>


            <div className={styles.left}>
                <header className={styles.header}>
                    Expense Details
                </header>
                <div className='flex flex-col gap-3 w-full'>
                    <FlexText leftText='Left' rightText='Right' />
                </div>
            </div>

            <div className={styles.right}>
                <ApproveBtns />
            </div>
        </div>
    )
}

export default ApproveExpense