// import React from 'react'
import { useQuery } from 'react-query'

const useVenues= () => {
    const { data: venues } = useQuery(`/admin/venues`)

    return {
        venues: venues?.data
    }
}

const useAvailableVenues = ({branch_id = null}) => {
    const { data: venue } = useQuery(`/shop/bookings/${branch_id}/available/venues`, {enabled:Boolean(branch_id)})

    return {
        availableVenues: venue?.data
    }
}

const useNearestAvailaableVenues = ({branch_id = null}) => {
    const { data: venue } = useQuery(`/shop/bookings/${branch_id}/available/venues`, {enabled:Boolean(branch_id)})

    return {
        nearestAvailableVenues: venue?.data
    }
}

export { useVenues, useNearestAvailaableVenues, useAvailableVenues }