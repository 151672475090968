import React, { useState } from 'react'
import styles from './form.module.scss'
import { Text } from '../Text';

import { useForm, FormProvider } from "react-hook-form";
import { apiRequest } from '../../Services';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../store/slices/modalSlice';
import { Button } from '../Button';
import { useQueryClient } from 'react-query';
import { Box } from '@mui/material';
const Form = ({
    children, onSubmit = () => null,
    onSuccess = () => null, onError = () => null,
    header = false, method, url, defaultValues = {},
    successMessage = 'Successful', btnText = false,
    btnProps = '', extraFields = {}, contentType = 'application/json', className, direction
}) => {
    const methods = useForm({ defaultValues })
    const {  watch } = methods
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const queryClient = useQueryClient()
    const submit = async (e) => {
        e.preventDefault()
        // console.log(watch())
        onSubmit(watch())

        setLoading(true)
        try {
            if (url) {
                const data = await apiRequest({ method, url, payload: { ...watch(), ...extraFields }, hasAuth: true, contentType })
                // console.log(data)
                toast.success(successMessage)
                dispatch(closeModal())
                onSuccess(data)
                queryClient.invalidateQueriesn(['newUpdate'])
                return data
            }
        } catch (error) {
            // console.log(error)
            const errors = error?.response?.data?.data?.errors
            onError(error)
            if (error?.response?.data?.data?.errors) {
                console.log(errors)
            }
        } finally {
            setLoading(false)
        }

    }
 
    return (
        <FormProvider {...methods} >
            <Box component={'form'} flexDirection={direction || 'column'} className={`${styles.formContainer}  ${className}`} onSubmit={submit} >
                {header && <Text width='100%' align='left' size='36px' weight='600' lHeight='36px'>{header}</Text>}
                {children}
                {btnText && <Button type={'submit'} disabled={loading} loading={loading} {...btnProps}>{btnText}</Button>}
            </Box>
        </FormProvider>
    )
}

export default Form