import { configureStore, combineReducers } from '@reduxjs/toolkit'
import modalSlice from './slices/modalSlice'
import userSlice from './slices/userSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import menuItemSlice from './slices/menuItemSlice';
import packageItemSlice from './slices/packageItemSlice';
import customerSlice from './slices/customerSlice';
import discountSlice from './slices/discountSlice';
import listSlice from './slices/listSlice';
import filterSlice from './slices/filterSlice';
import notificationSlice from './slices/notificationSlice';
// import packageItemSlice  from './slices/packageItemSlice';


const persistConfig = {
  key: 'beMedia',
  storage,
  blacklist: ['modal', 'menuItem', 'packageItem', 'customer', 'discounts', 'list', 'filter', 'notification']
}


const reducers = combineReducers({
  modal: modalSlice,
  user: userSlice,
  menuItem: menuItemSlice,
  packageItem: packageItemSlice,
  customer: customerSlice,
  discounts: discountSlice,
  list: listSlice,
  filter: filterSlice,
  notification: notificationSlice
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export const persistor = persistStore(store)





