import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    channel: '',
    event: '',
    id: ''
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        triggerNotification: (state, { payload: { channel, event, id } }) => {
            state.channel = channel
            state.event = event
            state.id = id
        }
    },
})

// Action creators are generated for each case reducer function
export const { triggerNotification } = notificationSlice.actions

export default notificationSlice.reducer