// import React from 'react'
import { useQuery } from 'react-query'

const useBranches = (type) => {
    const { data: branches } = useQuery(type = 'customer' ? '/shop/branches' : 'admin/branches', { refetchOnWindowFocus: false })

    return {
        branches: branches?.data
    }
}

export { useBranches }