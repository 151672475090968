import React, { useEffect, useId, useMemo, useState } from "react";
import styles from "./input.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { Text } from "../Text";
import { useFormContext } from "react-hook-form";
import { apiRequest } from "../../Services";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { keydownAvoid_e_input } from "utils";

// import dayjs from 'dayjs';
const Input = React.memo(
  ({
    label = "",
    placeholder = "Placeholder",
    type = "text",
    disabled = false,
    icon = false,
    option = [],
    onChange = () => null,
    // value,
    required = false,
    name = "name",
    defaultValue = "",
    min = 1,
    onFocus = () => null,
    ...props
  }) => {
    const id = useId();
    const { setValue, register, watch } = useFormContext();
    const [, setFieldValue] = useState("");
    const [error] = useState({});

    useEffect(() => {
      if (!watch(name)) {
        if (setValue) setValue(name, defaultValue);
      }
    }, [defaultValue, setValue, name, watch]);

    const TextInput = () => {
      const change = (e) => {
        onChange(e);
        setFieldValue(e.target.value);
        setValue(name, e.target.value);
      };
      return (
        <input
          key={name || id}
          type={type}
          name={name}
          min={props?.min <= 1 ? 0 : props.min}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          defaultValue={defaultValue}
          {...register(name, { required })}
          onChange={change}
          {...props}
          onKeyDown={(e) => keydownAvoid_e_input(e, type)}
        />
      );
    };

    const PasswordInput = () => {
      const [passwordType, setPasswordType] = useState(type === "password");

      return (
        <>
          <input
            type={passwordType ? "password" : "text"}
            name={name}
            placeholder={placeholder}
            id={id}
            disabled={disabled}
            {...register(name, { required })}
            onChange={onChange}
            {...props}
          />
          <span
            className="cursor-pointer"
            onClick={() => setPasswordType((prev) => !prev)}
          >
            {passwordType ? <Visibility /> : <VisibilityOff />}
          </span>
        </>
      );
    };

    const TelInput = () => {
      const [event, setEvent] = useState({
        target: {
          name,
          value: defaultValue,
        },
      });
      useEffect(() => {
        onChange(event);
        setValue(event.target.name, event.target.value);
        setFieldValue(event.target.value);
        // console.log(event.target.value, defaultValue)
      }, [event]);
      return (
        <PhoneInput
          countryCodeEditable={false}
          country={"ng"}
          value={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          {...register(name)}
          onChange={(value) => {
            setEvent((prev) => ({ ...prev, target: { name, value } }));
            return event;
          }}
          {...props}
        />
      );
    };

    const SelectInput = () => {
      const [loading, setLoading] = useState(false);
      const change = (e) => {
        const value = props?.isMulti ? e?.map((x) => x?.value) : e?.value;

        const event = {
          target: {
            name,
            value,
          },
        };
        // console.log(event)
        onChange(event);
        setValue(name, event?.target.value);
        setFieldValue(event?.target?.value);
      };
      const [defaultSelect, setDefaultSelect] = useState(
        option?.find((x) => x?.value === defaultValue)
      );

      useEffect(() => {
        if (!props.isMulti)
          setDefaultSelect(option?.find((x) => x?.value === defaultValue));

        if (props.isMulti) {
          // console.log('is multi')
          setDefaultSelect(defaultValue);
          // console.log(defaultValue, option)
        }

        setLoading(props?.loading);
        // eslint-disable-next-line
      }, [defaultValue, option, props?.loading, props.isMulti]);
      // console.log(defaultValue)

      const re_render = useMemo(
        () => (
          <Select
            options={option}
            isLoading={loading}
            classNames={{
              container: (prev) => "border !h-[25px] !my-1 !mt-[-5px] ",
            }}
            styles={{
              container: (styles) => ({
                width: "100%",
                ...styles,
                outline: "none",
                padding: 0,
                border: "none",
                position: "relative",
                height: "50px !impo",
              }),
              control: (styles) => ({
                width: "100%",
                ...styles,
                background: "inherit",
                outline: "none",
                padding: 0,
                border: "none",
                minWidth: "100%",
              }),
              menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            placeholder={placeholder}
            isDisabled={disabled}
            defaultValue={defaultSelect}
            name={name}
            onChange={change}
            isClearable
            {...props}
            // eslint-disable-next-line
          />
        ),
        [props, defaultSelect, defaultValue, loading, option]
      );

      return <>{!loading && re_render}</>;
    };
    const TextArea = () => {
      const change = (e) => {
        onChange(e);
        setFieldValue(e.target.value);
        setValue(name, e.target.value);
      };
      return (
        <textarea
          cols="30"
          rows="3"
          type={type}
          name={name}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          onFocus={onFocus}
          defaultValue={defaultValue}
          {...register(name, { required })}
          onChange={change}
          {...props}
        ></textarea>
      );
    };

    const DatePicker = () => {
      // Convert the default value to a Date object
      const [datetime, setDateTime] = useState(
        defaultValue ? new Date(defaultValue) : ""
      );

      const change = (e) => {
        setDateTime(e);
        onChange({ target: { name, value: e } });
        setValue(name, e);
      };

      const props =
        type === "time"
          ? {
              showTimeSelect: true,
              showTimeSelectOnly: true,
              timeIntervals: 15,
              timeCaption: "Time",
              dateFormat: "h:mm aa",
            }
          : type === "date-time"
          ? {
              showDateSelect: true, // Hypothetical property for date selection
              dateFormat: "MMMM d, yyyy h:mm aa", // Ensure time format is included
              showTimeSelect: true,
            }
          : {};

      return (
        <ReactDatePicker
          selected={datetime}
          placeholderText={placeholder || "Select date"}
          onChange={change}
          className="!w-[100%] !border"
          disabled={disabled}
          {...props}
        />
      );
    };

    return (
      <>
        <div
          className={styles.inputContainer}
          style={{ display: props.hidden && "none" }}
        >
          {label && <label htmlFor={id}>{label} </label>}
          <div className={styles.inputBox}>
            {icon && <div>{icon}</div>}
            {(type === "text" || type === "number") && TextInput()}{" "}
            {/* {type === 'text' && <TextInput />}  won't work cause it doesn't update directly  */}
            {type === "tel" && TelInput()}
            {type === "password" && PasswordInput()}
            {type === "select" && SelectInput()}
            {type === "textarea" && TextArea()}
            {type === "date-time" && DatePicker()}
            {(type === "date" || type === "time") && DatePicker()}
            {/* {icon && <div>{ricon}</div>} */}
          </div>
          <div className={styles.error}>
            <Text color="red" align="left" size="12px">
              {error[name] && error[name]}
            </Text>
          </div>
        </div>
      </>
    );
  }
);
const CheckInput = ({
  label = "Label",
  placeholder = "Placeholder",
  type = "text",
  disabled = false,
  onChange = () => null,
  name,
  defaultChecked = false,
  // value = ''
  ...props
}) => {
  // const id = useId()
  const { setValue } = useFormContext();
  const [fieldValue, setFieldValue] = useState("");
  useEffect(() => {
    setValue(name, fieldValue?.target?.checked);
    // onChange(fieldValue)xs
  }, [fieldValue, name, setValue]);
  const ToFunction = () => (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          // indeterminate={checked[0] !== checked[1]}
          // sx={{bgcolor:'red'}}
          style={{
            color: "#FF0030",
          }}
          name={name}
          {...props}
          // value={value}
          defaultChecked={defaultChecked}
          onChange={(e) => {
            setFieldValue(e);
            onChange(e);
          }}
        />
      }
    />
  );
  return ToFunction();
};
const FileInput = ({
  label = "Upload file",
  // placeholder = 'Placeholder',
  // disabled = false,
  onChange = () => null,
  // name = 'name',
  defaultValue = "",
  children,
  name = "file",
}) => {
  const [preview, setPreview] = useState(defaultValue);
  // console.log('first')
  const handleChange = (e) => {
    // console.log(e.target.files[0],'dd')
    try {
      // console.log(e.target.files[0])
      const show = URL.createObjectURL(e.target.files[0]);

      setPreview(show);
      onChange({
        target: {
          name: e.target.name,
          value: e.target.files[0],
        },
      });
      // console.log(e.target.files[0],'after')
    } catch (error) {
      console.log({ error });
    }
  };
  const id = `behive-file-Input-${name}`;
  return (
    <>
      {" "}
      <Box className={styles.fileInput} component={"label"} htmlFor={id}>
        <input hidden type="file" id={id} name={name} onChange={handleChange} />
        {preview && (
          <img
            src={preview}
            alt=""
            height={"300px"}
            width={"100%"}
            style={{ maxWidth: "100%" }}
          />
        )}
        {!preview && label}

        {children}
      </Box>
    </>
  );
};
const EditInput = ({
  label = "Label",
  placeholder = "Placeholder",
  // type = 'text',
  // disabled = false,
  // onChange = () => null,
  url = " ",
  method = "patch",
  defaultValue = "",
  name = "",
}) => {
  const id = Math.random();
  const [edit, setEdit] = useState(false);
  const [payload, setPayload] = useState({ name: "", url: "" });

  const handleEdit = ({ target: { name, value } }) =>
    setPayload((prev) => ({ ...prev, name, url: value }));

  const editClick = () => {
    setEdit((prev) => !prev);
    const onEdit = async () => {
      try {
        await apiRequest({ url, method, payload });
        toast.success("Successful");
      } catch (error) {}
    };
    if (edit && payload.name.length && payload.url.length) onEdit();
  };
  return (
    <Box className={styles.editInput}>
      <label htmlFor="">{label}</label>
      <Box component={"div"}>
        <input
          id={id}
          placeholder={placeholder}
          name={name}
          autoFocus={edit}
          defaultValue={defaultValue}
          onFocus={editClick}
          onBlur={() => setEdit(false)}
          value={payload.value}
          onChange={handleEdit}
        />
        <label htmlFor={id}>
          {" "}
          <Text onClick={editClick} isLink color="#FF0030">
            {edit ? "Save" : "Edit"}
          </Text>
        </label>
      </Box>
    </Box>
  );
};

export { Input, CheckInput, FileInput, EditInput };
