import { Box } from '@mui/material'
import { BtnStack, FlexText, Text } from 'Components'
import ReviewConfirm from 'Pages/Operations/Bookings/components/ReviewConfirm'
import ApproveBtns from 'Pages/Operations/WorklList/ApproveBtns'
import dayjs from 'dayjs'
import { useBranches } from 'hooks'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { changeTitle, openModal } from 'store/slices/modalSlice'
import { formatDate } from 'utils/formatPrice'


const OverDraftApproval = ({ submitText, next = () => null, prev = () => null, title = 'Review & Confirm' }) => {
    const dispatch = useDispatch()
    // const [loading, setLoading] = useState()
    // const { branches } = useBranches() 
    const { item_id } = useParams()


    const { data: worklist, refetch } = useQuery(`/operations/worklists/${item_id}`, { enabled: Boolean(item_id) })
    // console.log(worklist?.data)
    const workable_data = worklist?.data?.workable
    console.log(worklist?.data)
    useEffect(() => {
        dispatch(changeTitle(title))
    }, [dispatch, title])
    // const previous = () => {

    //     prev()
    // }
    // const nextClick = () => {
    //     next()
    // }
    // // const Modal = () => {


    return (
        <Box className={'flex flex-col items-center justify-center h-[100%]   mx-auto'}>
            {/* <ReviewConfirm
                leftText={'Decline'}
                // prev={() => setCurrent(0)}
                submitText={loading ? 'Loading...' : 'Approve'}
            // next={() => submit()}
            > */}


            <div className='w-[90%] gap-[40px] flex flex-col '>
                <Text size='24px' weight='600' align='center'> Overdraft Approval </Text>
                <div className='flex flex-col gap-3 font-[20px]'>
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Date'} rightText={dayjs(workable_data?.created_at).format('MMM DD, YYYY. hh:mm A')} />


                    <FlexText leftWeight={500} rightWeight={600} leftText={'Account No'} rightText={workable_data?.account_no} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Incoming overdraft'} rightText={workable_data?.incoming_overdraft} />

                    <FlexText leftWeight={500} rightWeight={600} leftText={'Status'} rightText={` ${workable_data?.overdraft_status} `} />
                    {/* 
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Receiving branch'} rightText={workable_data?.to_inventory_source?.branch?.name} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Receiving inventory'} rightText={workable_data?.to_inventory_source?.name} />
                    <FlexText leftWeight={500} rightWeight={600} leftText={'Receiving quantity'} rightText={`${workable_data?.inventory_item?.boh_to_moh_conversion_rate?.moh * worklist?.data?.workable?.amount}  ${workable_data?.destinationUnit} `} />


                    <FlexText leftWeight={500} rightWeight={600} leftText='Reason' rightText={workable_data?.reason} /> */}
                </div>
                <ApproveBtns />
            </div>

            {/* </ReviewConfirm> */}

        </Box>
    )
}

export default OverDraftApproval;