import React, { useEffect, useMemo, useState } from 'react';
// import styles from './PurchaseOrder.module.scss';
import { Header } from 'Partial/Header';
import { BsPlus } from 'react-icons/bs';
import { Button, DeleteModal, FlexText, Table, Text } from 'Components';
import { icons } from 'Assets/icons';
import { BsFillBellFill } from 'react-icons/bs'
import VerticalProgressStep from 'Components/ProgressStep/VerticalProgress';
import { AiTwotoneDollarCircle } from 'react-icons/ai';
import { Box } from '@mui/material';
import { ImCheckmark } from 'react-icons/im';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { apiRequest } from 'Services';
// import { ApprovePurchaseOrder } from './ApprovePurchaseOrder'
import { toast } from 'react-toastify';
// import ApproveBtns from '../WorklList/ApproveBtns';
import { formatDate } from 'utils/formatPrice';
import { Check } from '@mui/icons-material';
import { openModal } from 'store/slices/modalSlice';
import { useDispatch } from 'react-redux';
import MarkAsPaidDeets from './MarkAsPaidDeets';

const PurchaseOrder = ({ item_id }) => {
  const { id, type } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: suppliers } = useQuery('admin/suppliers')
  const [update, setUpdate] = useState('111')
  // console.log(singlePurchaseOrder)
  const column1 = [
    { name: 'Date', key: 'created_at', render: (data) => dayjs(data?.created_at).format('DD MMM, YYYY. hh:mm') },
    { name: 'Purchase order ID', key: 'purchase_order_no' },
    { name: 'Vendor name', key: 'vendor', render: (data) => suppliers?.data?.find(x => x.id === data?.supplier_id)?.company_name },
    // { name: 'Items requests', key: 'requests' },
    { name: 'Total amount (₦)', key: 'total' },
    { name: 'Paid Through', key: 'paid_through' },
    { name: 'Status', key: 'status', render: (data) => <span className='capitalize'>{data?.order_status}</span> },
    {
      name: 'Action', key: 'paid_through', render: (data) => <Button onClick={
        () => dispatch(openModal({ title: 'Purchase Order Detail', content: <MarkAsPaidDeets data={data} /> }))
      } bgcolor='#EFEFEF' radius='100px' color='#FF0030'>Mark As Paid</Button>
    },
  ]

  // const ≥
  const mark_as_delivered = async (id) => {
    try {
      await apiRequest({
        url: `/operations/purchase-orders/${id}/mark-as-delivered`,
        hasAuth: true
      })
      setUpdate(Math.random())
      toast.success('Successful')
    } catch (error) {
      console.log(error)

    }
  }




  return (
    <div >

      <Header
        name={'Purchase Order'}
        // text={'Purchgase Order'}
        buttonOnclick={() => navigate('/operations/purchaseorder/add')}
      />

      <Table
        key={update}
        column={column1}
        url={'/operations/purchase-orders?'}
        action={false}
        fn={(list) => list?.filter(data => data?.order_status === 'delivered')}
      //   actions={(data) => (
      //     <div>
      //       <Button bgcolor='inherit ' shadow={false} color='black' onClick={() => navigate(`/operations/purchaseorder/${data?.id}/`)} > {icons.viewDetail} View Details</Button>
      //       {
      //         (data?.status === 'approved' && data?.delivered_at === null) &&
      //         <Button bgcolor='inherit ' shadow={false} color='black' onClick={() => mark_as_delivered(data?.id)} > {icons.viewDetail} Mark as delivered</Button>
      //       }
      //       {data?.status === 'pending' && <Button bgcolor='inherit ' shadow={false} color='#EB2F2F'  onClick={() => dispatch(openModal({ title: 'Delete confirmation', content: <DeleteModal message={'Do you want to delete this purchase order?'} url={`/operations/purchase-orders/${data?.id}`} /> }))}
      //      >  {icons.delete} Delete</Button>
      //       } </div>
      //   )}
      />

    </div>

  );
};

export default PurchaseOrder;
