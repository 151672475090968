import { useEffect, useMemo, useState } from 'react';
import styles from './ManualStockAdjustment.module.scss';
import { Table, Button, Form, Input } from "Components";
import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { BiArrowBack } from 'react-icons/bi';
import { BsPlusLg } from 'react-icons/bs';
import { apiRequest } from 'Services';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { icons } from 'Assets/icons';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import uuid from 'react-uuid';

const ManualStockAdjustmentDetails = () => {
  const { data: inven_items } = useQuery(`/admin/inventory-items`)
  const { data: inven_source } = useQuery(`/admin/inventory-source`)
  const { id } = useParams()
  const { data: single_stock } = useQuery(`operations/stock-adjustments/${id}`, { enabled: Boolean(id) })
  console.log(single_stock?.data)
  const [updater, setUpdater] = useState({ index: null, name: null })
  // const { data: units } = useQuery(`/admin/units`)
  const navigate = useNavigate();
  const [items, setItems] = useState([{
    id: new Date().getTime(),
    item_id: null, quantity: null, unit: null,
    reason: null, inventory_source_id: null, transaction_type: 'debit'
  }])

  const handleItemChange = (e, i) => {

    setUpdater({ index: i, name: e.target.name })
    setItems(prev => {
      let newItems = prev
      newItems[i] = { ...newItems[i], [e.target.name]: e.target.value }
      return newItems
    })

  }

  const deleteIem = (id) => {
    // console.log(id)
    setItems(prev => prev.filter(x => x.id !== id))
    // console.log(items)
  }
  const Drops = ({ options, display, change = () => null, name, value, ...props }) => (
    <select onChange={change} name={name} defaultValue={value} {...props} disabled>
      <option value="" className=''>Select {display}</option>
      {options?.map(prop => <option key={prop.value} value={prop?.value}>{prop?.label}</option>)}
    </select>
  )
  useEffect(() => {
    if (updater.index !== null) {
      const item_id = items[updater.index].item_id
      const inventory_source_id = items[updater.index].inventory_source_id
      if (item_id && inventory_source_id) {
        // console.log(item_id, inventory_source_id)
        let unit = ''
        const item = inven_items?.data?.find(item => item?.id === parseInt(item_id))
        const source = inven_source?.data?.find(source => source?.id === parseInt(inventory_source_id))
        if (source?.type === 'boh') unit = item?.boh_unit?.short_name
        if (source?.type === 'moh') unit = item?.moh_unit?.short_name
        setItems(prev => {
          let newItems = prev
          newItems[updater.index] = { ...newItems[updater.index], unit }
          return newItems
        })
      }
    }
    // eslint-disable-next-line
  }, [updater, inven_items?.data, inven_source?.data])
  // const transtype = ['credit', 'debit']

  const columns = useMemo(() => [
    { key: 'item_id', name: 'Item', render: (data, i) => <Drops name={`item_id`} change={(e) => handleItemChange(e, i)} display={'item'} value={data?.item_id} options={inven_items?.data?.map(x => ({ label: x?.name, value: x?.id }))} /> },
    { key: 'quantity', name: 'Quantity',},
    // { key: 'unit', name: 'Unit', render: (data, i) => <Drops name={`unit`} disabled display={'unit'} defaultValue={data?.unit} options={units?.data?.map(x => ({ label: x?.long_name, value: x?.id }))} /> },
    { key: 'unit', name: 'Unit' },
    // { key: 'transaction_type', name: 'Transaction type', render: (data, i) => <Drops name={`transaction_type`} change={(e) => handleItemChange(e, i)} display={'transaction type'} options={transtype?.map(x => ({ label: x, value: x }))} /> },
    { key: 'inventory_source_id', name: 'Sub-Inventory', render: (data, i) => <Drops change={(e) => handleItemChange(e, i)} display={'inventory source'} name={'inventory_source_id'} options={inven_source?.data?.map(x => ({ label: x?.name, value: x?.id }))} value={data?.inventory_source_id} /> },
    { key: 'reason', name: 'Reason'},
    // { key: 'k', name: '', render: (data, i) => <Close onClick={() => deleteIem(data.id)} /> },
    // eslint-disable-next-line 
  ], [handleItemChange, items, inven_source?.data, inven_items?.data, updater])

  const [, setFormValues] = useState({})

  return (
    <div className={styles.body}>
      <Box className='flex items-center border-b text-[1.5rem] py-4 w-full' >
        <button className='text-red-500' onClick={() => navigate(-1)}><BiArrowBack /></button> &nbsp; <p className='font-bold'> Manual transaction details</p>
      </Box>
      <div className='px-[24px] pt-[12px] w-full pb-[24px]'>
        <Form>
          <Box className={`self-start my-3 lg:w-[60%] w-full`}>

            <Box className={`my-3`}>
              <Input label='Reference' disabled value={single_stock?.data?.reference} placeholder='123456' className='mb-4' />
              <Input type='textarea' disabled label='Notes' placeholder='Describe...' name='note' value={single_stock?.data?.note}/>
            </Box>
          </Box>
        </Form>
        <Box className='self-start ps-0 ms-0 w-full '>
          <Table
            column={columns}
            action={false}
            actions={(data) => (
              <div>
                <Button bgcolor='inherit ' shadow={false} color='#EB2F2F' onClick={() => deleteIem(data.id)}>  {icons.delete} Delete</Button>
              </div>
            )}
            data={single_stock?.data?.entries}
          />



        </Box>

      </div>
    </div>
  );
};

export default ManualStockAdjustmentDetails;
