// import GL_AccountReport from "Pages/Accounting/Report/GL_AccountReport"
// import Income from "Pages/Accounting/Report/Income"
import { lazy } from "react"
import GL_AccountReport from "./Report/GL_AccountReport"
import Income from "./Report/Income"
import ManualDetails from "./ManualJournal/ManualDetail"
import Approve from "Pages/Operations/WorklList/approve"
import Orders from "Pages/Operations/Orders"
import InvoiceDetails from "./Invoice/InvoiceDetails"
import Funding from "Pages/Pos/Funding"
import SaleMargin from "Pages/Pos/Reports/SaleMargin"
import GlAccountTransactions from "./Report/GlAccountTrans"
import ProfitLoss from "./Report/ProfitLoss"
import BalanceSheet from "./Report/BalanceSheet"
import CashFlow from "./Report/CashFlow"
import AgingReceivable from "Pages/Operations/AgingReceivable"
import PurchaseOrder from "./PurchaseOrder"

const Expenses = lazy(() => import("./Expenses"))
const Invoice = lazy(() => import("./Invoice"))
const CreateInvoice = lazy(() => import("./Invoice/CreateInvoice"))
const ManualJournal = lazy(() => import("./ManualJournal"))
const AddManualJournal = lazy(() => import("./ManualJournal/AddManualJournal"))
const Assets = lazy(() => import("./Assets"))
const WorklList = lazy(() => import('../Operations/WorklList'));

// const Income = lazy(() => import("./Reports/Income"));
// import GLAccountReport from "./Reports/GL_AccountReport";

const layout = { header: true, sidebar: true }
export const accounting_routes = [
    { Component: <Expenses />, path: '/accounting/', layout, isPublic: false, permitkey: '' },
    { Component: <Expenses />, path: '/accounting/expenses', layout, isPublic: false, permitkey: 'view accounting expenses' },
    { Component: <PurchaseOrder />, path: '/accounting/purchase-order', layout, isPublic: false, permitkey: 'view accounting expenses' },
    { Component: <ManualJournal />, path: '/accounting/manual-journal', layout, isPublic: false, permitkey: '' },
    { Component: <AddManualJournal />, path: '/accounting/manual-journal/add', layout, isPublic: false, permitkey: '' },
    { Component: <ManualDetails />, path: '/accounting/manual-journal/:id', layout, isPublic: false, permitkey: '' },
    { Component: <Invoice />, path: '/accounting/invoice', layout, isPublic: false, permitkey: '' },
    { Component: <CreateInvoice />, path: '/accounting/invoice/create', layout, isPublic: false, permitkey: '' },
    { Component: <InvoiceDetails />, path: '/accounting/invoice/view-details/', layout, isPublic: false, permitkey: '' },
    { Component: <Assets />, path: '/accounting/assets', layout, isPublic: false, permitkey: '' },
    { Component: <WorklList />, path: '/accounting/WorkList'.toLowerCase(), layout, isPublic: false, permitkey: '' },
    { Component: <Income />, path: '/accounting/income-report', layout, isPublic: false, permitkey: '' },
    { Component: <Orders />, path: '/accounting/orders', layout, isPublic: false, permitkey: '' },
    { Component: <GL_AccountReport />, path: '/accounting/gl-account-report', layout, isPublic: false, permitkey: '' },
    { Component: <Funding />, path: '/accounting/funding', layout, isPublic: false, permitkey: '' },
    { Component: <SaleMargin />, path: '/accounting/sales-margin-report', layout, isPublic: false, permitkey: '' },
    { Component: <GlAccountTransactions />, path: '/accounting/gl-account-transactions', layout, isPublic: false, permitkey: '' },
    { Component: <ProfitLoss />, path: '/accounting/profit-and-loss', layout, isPublic: false, permitkey: '' },
    { Component: <BalanceSheet />, path: '/accounting/balance-sheet', layout, isPublic: false, permitkey: '' },
    { Component: <CashFlow />, path: '/accounting/cash-flow', layout, isPublic: false, permitkey: '' },
    { Component: <AgingReceivable />, path: '/accounting/AgingReceivable'.toLowerCase(), layout, isPublic: false, permitkey: '' },
    { Component: <Approve />, path: '/accounting/WorkList/approve/:type/:id/:item_id'.toLowerCase(), layout, isPublic: false, permitkey: '' },


]