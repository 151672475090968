import React, { useEffect } from 'react'
import styles from './layout.module.scss'
import { PropTypes } from "prop-types";
import Sidebar from '../Sidebar'
import Navbar from '../Navbar'
import BeHivePusher from 'Components/Pusher'
import { refreshToken } from 'Services/refreshToken';
import { useMe } from 'hooks';
import { updateRoles } from 'store/slices/userSlice';
import { useDispatch } from 'react-redux';

const Refresh = () => {
  useEffect(() => {
    timer = setInterval(() => {
      refreshToken()
    }, 5000);
    return () => {
      clearInterval(timer)
    }
  }, [])




  return <></>
}

const CheckPermission = () => {
  const dispatch =  useDispatch()
  const { roles } = useMe()
  if(roles?.length)  dispatch(updateRoles(roles))
  return <></>
}

let timer = null
function Layout({ name, children, show }) {
  return (
    show ?
      <div className={styles.layout}>
        <Navbar />
        <BeHivePusher />
        <Refresh />
        <CheckPermission />
        <body>
          <div className={styles.sidebar}> <Sidebar /></div>
          <main>
            {children}
          </main>
        </body>
        {name}
      </div> :
      <>
        {children}
        <BeHivePusher />
      </>
  )
}
Layout.prototype = {
  name: PropTypes.string
}
export default Layout
