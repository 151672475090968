import { FlexText } from 'Components'
import React from 'react'
import { formatPrice } from 'utils'

const Balancedetails = ({customer}) => {
    return (
        <div className='w-[100%]'>

            {<FlexText leftText=' Wallet balance ' rightText={formatPrice(customer?.wallet?.balance)} />}
            {<FlexText leftText=' Available balance ' rightText={formatPrice(customer?.wallet?.balance + customer?.wallet?.overdraft)} />}
        </div>
    )
}

export default Balancedetails