import React from "react";
import { Input, Form } from "../../../Components";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
// import  from '../../../Components/Form';

const ChangePassword = ({ fromDefault }) => {
  console.log(fromDefault);
  const { search } = useLocation();
  const state = useSelector((state) => state.user);
  const access_token = state?.access_token;

  console.log(state);
  const aws_session_key = new URLSearchParams(search).get("session-key") || "";
  const username = new URLSearchParams(search).get("email") || "";
  // if (session_key) aws_session_key = {aws_session_key:session_key}
  let extras = {};
  if (fromDefault) {
    if (aws_session_key) extras.aws_session_key = aws_session_key;
    if (username) extras.username = username;
    if (access_token) extras.access_token = access_token;
  } else {
    if (access_token) extras.access_token = access_token;
    extras.username = state.details.email;
  }

  return (
    <Form
      btnText={"Submit"}
      url={fromDefault ? "/auth/password/update" : "auth/password/change"}
      method={"post"}
      successMessage="Password changed. Please log in again"
      extraFields={{ ...extras }}
      onSuccess={() => {
        if (fromDefault) {
          toast.info("Please login with new password");
          setTimeout(() => {
            window.location.assign("/login");
          }, 2000);
        }
      }}
    >
      <Input
        label="Current Password"
        placeholder="Enter current password"
        type="password"
        name="current_password"
      />
      <Box sx={{ display: "none" }}>
        <Input
          label="Current Password"
          placeholder="Enter current password"
          type="password"
          name="logout_of_all_other_devices"
          defaultValue={true}
        />
      </Box>
      <Input
        label="New Password"
        placeholder="Enter New Password"
        type="password"
        name="new_password_confirmation"
      />
      <Input
        label="Confirm Password"
        placeholder="Confirm Password"
        type="password"
        name="new_password"
      />
      {/* <Button></Button> */}
    </Form>
  );
};

export default ChangePassword;
