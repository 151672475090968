import React from 'react';
import { Header } from 'Partial/Header';
import { Button, Table } from 'Components';
import { BsChevronRight } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/slices/modalSlice';
import Details from './Details';
import Mark from './Mark';
import ConfirmPayment from 'Pages/Waiter/ConfirmPayment';
import { formatDate } from 'utils/formatPrice';
import FilterByList from 'Components/Button/FilterByList';

const AgingReceivable = () => {
  const dispatch = useDispatch()
  // const format 
  const columns = [
    { name: "Created at", key: 'created_at', render: (data) => formatDate({ date: data?.created_at }) },
    { name: "Aged at", key: 'aged_at', render: (data) => formatDate({ date: data?.aged_at }) },
    { name: "Transaction ID", key: 'unique_id' },
    { name: "Customer name", key: 'date-time', render: (data) => data?.customer?.full_name },
    { name: "Phone number", key: 'date-time', render: (data) => data?.customer?.phone },
    { name: "Order type", key: 'date-time', render: (data) => data?.order_type?.type },
    {
      name: "Amount", key: 'date-time', render: (data) => <Button
        onClick={() => dispatch(openModal({
          title: 'Aging receivable',
          content: <ConfirmPayment onSuccess={() => null} order={data} unique_id={data?.unique_id} id={data?.id} />
        }))}
        bgcolor='#EFEFEF' w='fit-content' radius='24px' color='#FF0030'>Mark as Paid</Button>
    },
    {
      name: "Action", key: 'date-time', render: (data) => <BsChevronRight
        onClick={() => dispatch(openModal({ title: 'Aging receivable detail', content: <Details data={data} /> }))} />
    },
  ]
  const filters = [
    {
      name: 'Status', key: 'status', list: [
        ...['Completed', 'Pending'].map(btn => ({ name: btn, value: btn?.toLowerCase() }))
      ]
    },
  ]
  return (
    <>
      <Header name={'Aging receivable'} text={false} export_name={'aging-receivables'}  />
      <Table

        column={columns}
        // data={[{'date-time':'data'}]}
        url='/shop/aging-receivables'

        actionIcon={<BsChevronRight />}
        action={false}
      />

    </>
  );
};

export default AgingReceivable;
