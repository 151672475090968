import React, { useState } from 'react'
import { FileInput, Input } from 'Components'
// import styles from './staffmanagement.module.scss'
import Form from 'Components/Form'
import { Box } from '@mui/material'
import { useQuery } from 'react-query'
import { selectOpts } from 'utils'
import { formatDate } from 'utils/formatPrice'
import dayjs from 'dayjs'
import { apiRequest } from 'Services'
import { closeModal } from 'store/slices/modalSlice'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'

const MarkAsPaidForm = ({ data,id, method = 'post', message = "Successful", btnText = 'Mark As Paid', defaults }) => {


    const { data: accounts } = useQuery(`/admin/accounting/accounts`)
    const { data: supllier } = useQuery(`/admin/suppliers`)
    const { data: expenses } = useQuery(`/admin/accounting/expenses/${id}`, { enabled: Boolean(id) })
    const [date] = useState(dayjs(expenses?.data?.date).format('YYYY-MM-DD'))
    const dispatch = useDispatch()

    const [formValues, setFormValues] = useState({})
    const handleChange = (e) => {
        setFormValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    // const handleFileInput = (e) => {
    //     console.log(e.target.file)
    //     // setFormValues(prev => ({ ...prev, image: e.target.files[0] }))
    // }
    const submit = async () => {
        // alert('ff')
        console.log(formValues)
        const formData = new FormData()
        formData.append('_method', 'PUT')
        Object.entries(formValues).map(value => {
            // console.log(value[0], value[1])
            if (value[0] === 'paid_at') {
                
                formData.append(value[0], dayjs(value[1]).format('YYYY-MM-DD'))
                
            } else {
                
             
                formData.append(value[0], value[1])
            }
            return null;
        })
        console.log(formData)

        try {
            await apiRequest({
                // url: id ? `/admin/accounting/expenses/${id}` : '/admin/accounting/expenses',
                url: `/operations/purchase-orders/${data?.id}/mark-as-paid`,
                method:'post',
                payload: formData,
                hasAuth: true,
                contentType: 'multipart/form-data'
            })
            toast.success('Marked as paid')
            dispatch(closeModal())
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <Form contentType='multipart/form-data' onSubmit={submit} extraFields={{ date: dayjs(date).format('YYYY-MM-DD') }} btnText={btnText} successMessage={message} >
                <Input label='Date' placeholder='Enter date' type={'date'} name={'paid_at'} onChange={handleChange} required defaultValue={expenses?.data?.paid_at} />
                {/* <Input label='Expense Account' placeholder='Select account' onChange={handleChange} name='account_id'    option={(accounts?.data?.map(item => ({ value: item?.id, label: `${item?.code} ${item?.name}` })))}  type='select' required defaultValue={expenses?.data?.account} /> */}
                {/* <Box display={'flex'} gap={'16px'}> */}
                    <Input label='Amount' placeholder='0' icon="₦ " onChange={handleChange} name={'amount'} required defaultValue={expenses?.data?.amount} />
                    {/* <Input label='Tax' placeholder='select tax' name={'tax'} onChange={handleChange} required defaultValue={expenses?.data?.tax} /> */}
                {/* </Box> */}
                <Input label='Paid Through' placeholder='select account' type='select' onChange={handleChange} name={'paid_through_account_id' } option={(accounts?.data?.map(item => ({ value: item?.id, label: `${item?.code} ${item?.name}` })))} required defaultValue={expenses?.data?.paid_through} />
                {/* <Input label='Vendor' placeholder='select vendor' type='select' onChange={handleChange} name={'vendor_id'} option={selectOpts(supllier?.data, 'company_name')} required defaultValue={expenses?.data?.vendor_id} /> */}
                <Input label='Description' placeholder='Enter description' onChange={handleChange} type='textarea' name={'paid_comment'} required defaultValue={expenses?.data?.description} />

                <FileInput onChange={handleChange} name='receipt' />


            </Form>
        </div>
    )
}

export default MarkAsPaidForm