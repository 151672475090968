
import { assets } from '../../Assets'
const base = '/admin'
export const admin_paths = [
  { path: base + '/staff-management', name: 'Staff Mgmt', icon: assets.icons.staffMagt },
  { path: base + '/roles-and-permission', name: 'Roles & Permission', icon: assets.icons.rolesPermissions },
  { path: base + '/approval-hierachy', name: 'Approval Hierachy', icon: assets.icons.approvalHierarchy },
  { path: base + '/branches', name: 'Branches', icon: assets.icons.branches },
  { path: base + '/venue-management', name: 'Venue Management', icon: assets.icons.venueMgt },
  { path: base + '/tables', name: 'Tables', icon: assets.icons.tables },
  { path: base + '/short-let', name: 'Shortlet', icon: assets.icons.branches },
  { path: base + '/studio-equipment', name: 'Studio Equipments', icon: assets.icons.branches },
  // { path: base + '/professional-services', name: 'Professional Service', icon: assets.icons.branches },
  { path: base + '/service-price-list', name: 'Service Price List', icon: assets.icons.branches },
  { path: base + '/pos', name: 'POS', icon: assets.icons.pos },
  { path: base + '/discount', name: 'Discount', icon: assets.icons.discount },
  {
    path: base + '#', name: 'Menu', icon: assets.icons.menuMgt, children: [
      { path: base + '/menu-management', name: 'Menu management' },
      { path: base + '/menu-category', name: 'Menu category' },
      { path: base + '/ingredients', name: 'All ingredients' },
      { path: base + '/package-management', name: 'Package management' },
    ]
  },
  { path: base + '/inventory-management', name: 'Inventory Mgmt', icon: assets.icons.inventoryMgt },
  { path: base + '/inventory-source', name: 'Inventory source', icon: assets.icons.inventoryMgt },
  {
    path: base + '#', name: 'Accounting', icon: assets.icons.accounting, children: [
      { path: base + '/accounting-types', name: "Accounting  Types" },
      { path: base + '/chart-of-account', name: 'Chart Of Account' },
      { path: base + '/sbu-code', name: 'SBU Code' }
    ]
  },
  { path: base + '/shift-management', name: 'Shift Management', icon: assets.icons.suppliers },
  { path: base + '/suppliers', name: 'Suppliers', icon: assets.icons.suppliers },
  { path: base + '/order-types', name: 'OrderTypes', icon: assets.icons.orderType },
  { path: base + '/membership', name: 'Membership', icon: assets.icons.memberShip },
  // { path: base + '/referral-bonus', name: 'Referral Bonus', icon: assets.icons.referral },
  // { path: base + '/short-let-adjustment', name: 'Short-Let Adjustment', icon: assets.icons.memberShip },
  { path: base + '/business-info', name: 'Business Info', icon: assets.icons.businessInfo },
  { path: base + '/otps', name: 'OTPs', icon: assets.icons.businessInfo },
]